import { Table, Tag } from 'antd';
import {
  LuckyWheelRewardType,
  TypeTagColor,
} from '../../lucky-wheel-rewards/constant';
import { useState } from 'react';

interface Reward {
  id: number;
  name: string;
  amount: number;
  type: LuckyWheelRewardType;
  threshold: number;
}

export const MissionTable = ({ rewards }: { rewards: Reward[] }) => {
  const [data, setData] = useState(rewards);

  return (
    <Table
      dataSource={data}
      columns={[
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          render: (value: LuckyWheelRewardType) => (
            <Tag color={TypeTagColor[value]}>{value}</Tag>
          ),
        },
        {
          title: 'Threshold',
          dataIndex: 'threshold',
          key: 'threshold',
          render: (value: number) => value * 100 + '%',
        },
      ]}
    />
  );
};
