import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';
import { DatePicker, Form, Input } from 'antd';
import { PartnerRoles } from './constant/partnerRole';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
dayjs.extend(localeData);

export const PartnerEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  const role = formProps.initialValues?.role;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...{
          ...formProps,
          initialValues: {
            ...formProps.initialValues,
            joinedAt: formProps.initialValues?.joinedAt
              ? dayjs(new Date(formProps.initialValues.joinedAt))
              : undefined,
          },
        }}
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {role === PartnerRoles.AMBASSADOR && (
          <Form.Item label="Joined Date" name={['joinedAt']}>
            <DatePicker showTime />
          </Form.Item>
        )}
      </Form>
    </Edit>
  );
};
