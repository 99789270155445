import React from 'react';
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from '@refinedev/core';
import {
  Show,
  TextField,
  ImageField,
  NumberField,
  BooleanField,
  DateField,
} from '@refinedev/antd';
import { Typography } from 'antd';

const { Title } = Typography;

export const EarnCampaignShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>ID</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Icon</Title>
      <ImageField style={{ maxWidth: 200 }} value={record?.icon} />
      <Title level={5}>Slide Image</Title>
      <ImageField style={{ maxWidth: 200 }} value={record?.slideImage} />
      <Title level={5}>Background Image</Title>
      <ImageField style={{ maxWidth: 200 }} value={record?.backgroundImage} />
      <Title level={5}>Type</Title>
      <TextField value={record?.type} />
      <Title level={5}>Commission Fee</Title>
      <NumberField value={record?.commissionFee ?? ''} />
      <Title level={5}>Currency Unit</Title>
      <TextField value={record?.currencyUnit} />
      <Title level={5}>Product Link</Title>
      <TextField value={record?.productLink} />
      <Title level={5}>Platform</Title>
      <TextField value={record?.platform} />
      <Title level={5}>Is Slide</Title>
      <BooleanField value={record?.isSlide} />
      <Title level={5}>Is Active</Title>
      <BooleanField value={record?.isActive} />
      <Title level={5}>Start Date</Title>
      <DateField value={record?.startDate} />
      <Title level={5}>End Date</Title>
      <DateField value={record?.endDate} />
    </Show>
  );
};
