import React, { useMemo, useState } from 'react';
import {
  useApiUrl,
  useCustomMutation,
  useNotification,
  useOne,
  useResource,
} from '@refinedev/core';
import { ImageField } from '@refinedev/antd';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Tag,
} from 'antd';
import Meta from 'antd/es/card/Meta';
import { IKycLv2Detail } from '../../interfaces/kyc-lv2';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { REASON_LIST, REASON_MESSAGE_LANGUAGE } from '../../constants';

export const DrawerKycLv2Info: React.FC<{
  kycLv2Detail: IKycLv2Detail;
}> = ({ kycLv2Detail }) => {
  const [en, setEn] = useState<string>('');
  const [vn, setVn] = useState<string>('');
  const reason = useMemo(() => {
    return { en, vn };
  }, [en, vn]);

  const record = kycLv2Detail;
  const { data: countryData } = useOne({
    resource: 'countries/countryId',
    id: record?.country || '',
    queryOptions: {
      enabled: !!record,
    },
  });

  const documentNumber =
    record?.identityNumber ||
    record?.passportNumber ||
    record?.driverLicenseNumber ||
    record?.otherNumber;

  const status =
    record?.isActive === true ? (
      <Tag color="blue">Approved</Tag>
    ) : (
      <Tag color="red">Waiting for approval</Tag>
    );

  const apiUrl = useApiUrl();
  const { resource } = useResource();
  const { mutate, isLoading: updateStatusIsLoading } = useCustomMutation();
  const { open } = useNotification();
  const onClickApprove = (id: string) => {
    mutate(
      {
        url: `${apiUrl}/${resource?.name}/approve/${id}`,
        method: 'patch',
        values: {},
      },
      {
        onSuccess: () => {
          open?.({
            type: 'success',
            message:
              'Approve Successful! Please click Refresh button for reload Data.',
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
      },
    );
  };
  const onClickReject = (id: string) => {
    if (vn === '' || en === '') {
      open?.({
        type: 'error',
        message: 'Please choose the REASON before submit',
      });
    } else {
      mutate(
        {
          url: `${apiUrl}/${resource?.name}/reject/${id}`,
          method: 'patch',
          values: {
            reason,
          },
        },
        {
          onSuccess: () => {
            open?.({
              type: 'success',
              message:
                'Rejected Successful! Please click Refresh button for reload Data.',
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          },
        },
      );
    }
  };

  const handleChange = (e: string) => {
    const value = REASON_LIST[`${e}`];
    setVn(value.vn);
    setEn(value.en);
  };

  const onChangeEn = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const language = e.target.value;
    setEn(language);
  };

  const onChangeVn = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const language = e.target.value;
    setVn(language);
  };

  return (
    <>
      {record?.isActive || (
        <>
          <Space.Compact block>
            <Button
              type="primary"
              danger
              loading={updateStatusIsLoading}
              icon={<CheckCircleTwoTone twoToneColor="blue" />}
              onClick={() => onClickApprove(record.id)}
              style={{ marginBottom: 16 }}
            >
              Approve Documentation by Clicking here.
            </Button>
          </Space.Compact>
          <Card bodyStyle={{ backgroundColor: '#f5f5f5' }}>
            <Descriptions title="Reject Documentation" />
            <Space.Compact block>
              <label>Reason Template: &nbsp;</label>
              <Select
                onChange={handleChange}
                options={REASON_MESSAGE_LANGUAGE}
                style={{ width: '30%' }}
              />
            </Space.Compact>
            <br />
            <Space.Compact block>
              <label>VN: &nbsp;</label>
              <Input.TextArea value={vn} autoSize onChange={onChangeVn} />
            </Space.Compact>
            <br />
            <Space.Compact block>
              <label>EN: &nbsp;</label>
              <Input.TextArea value={en} autoSize onChange={onChangeEn} />
            </Space.Compact>
            <Button
              type="dashed"
              danger
              loading={updateStatusIsLoading}
              icon={<CheckCircleTwoTone twoToneColor="blue" />}
              onClick={() => onClickReject(record.id)}
              style={{ marginTop: 16 }}
            >
              Save Reject Reason
            </Button>
          </Card>
          <Divider />
        </>
      )}
      <Descriptions title="Document Information">
        <Descriptions.Item label="Country">
          {countryData?.data.name}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {record?.user?.email}
        </Descriptions.Item>
        <Descriptions.Item label="FullName">
          {record?.fullName}
        </Descriptions.Item>
        <Descriptions.Item label="Document Type">
          {record?.documentType}
        </Descriptions.Item>
        <Descriptions.Item label="Document Number">
          {documentNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">{record?.gender}</Descriptions.Item>
        <Descriptions.Item label="Birthday">
          {record?.birthday}
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {record?.description}
        </Descriptions.Item>
        <Descriptions.Item label="CreatedAt">
          {record?.createdAt}
        </Descriptions.Item>
        <Descriptions.Item label="UpdatedAt">
          {record?.updatedAt}
        </Descriptions.Item>
        <Descriptions.Item label="Status">{status}</Descriptions.Item>
        <Descriptions.Item label="Address">{record?.address}</Descriptions.Item>
      </Descriptions>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Row gutter={16}>
          <Col span={8}>
            <Card
              hoverable
              style={{ width: '100%', backgroundColor: 'gray' }}
              cover={<ImageField value={record?.imageFront} />}
            >
              <Meta title="Front Side" />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              hoverable
              style={{ width: '100%', backgroundColor: 'gray' }}
              cover={
                record?.imageBack ? (
                  <ImageField value={record?.imageBack} />
                ) : (
                  <Tag color="red">Image not found!</Tag>
                )
              }
            >
              <Meta title="Back Side" />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              hoverable
              style={{ width: '100%', backgroundColor: 'gray' }}
              cover={<ImageField value={record?.imageFace} />}
            >
              <Meta title="Face" />
            </Card>
          </Col>
        </Row>
      </Space>
    </>
  );
};
