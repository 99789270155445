import React from 'react';
import {
  BaseRecord,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNotification,
  useShow,
} from '@refinedev/core';
import { Show, DateField } from '@refinedev/antd';
import { Button, Table, Tag } from 'antd';
import { NOTIFICATION_TYPE } from '../../constants';
export const PushNotificationShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { open } = useNotification();
  const { mutate: callHandlePushNow } = useCustomMutation();
  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');
  const { data, isLoading } = queryResult;

  const record = data?.data;
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => <Tag color="pink">{id}</Tag>,
    },
    {
      title: 'Campaign Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'VN Title',
      dataIndex: 'vnTitle',
      key: 'vnTitle',
    },
    {
      title: 'VN Message',
      dataIndex: 'vnMessage',
      key: 'vnMessage',
    },
    {
      title: 'EN Title',
      dataIndex: 'enTitle',
      key: 'enTitle',
    },
    {
      title: 'EN Message',
      dataIndex: 'enMessage',
      key: 'enMessage',
    },
    {
      title: 'Start At',
      dataIndex: 'startAt',
      key: 'startAt',
      render: (value: any) => (
        <DateField format="DD/MM/YYYY" value={value?.slice(0, -1)} />
      ),
    },
    {
      title: 'End At',
      dataIndex: 'endAt',
      key: 'endAt',
      render: (value: any) => (
        <DateField format="DD/MM/YYYY" value={value?.slice(0, -1)} />
      ),
    },
    {
      title: 'Time',
      dataIndex: 'hours',
      key: 'hours',
      render: (_: any, record: BaseRecord) => (
        <Tag color="blue">
          {record.hour}h:{record.minute}m
        </Tag>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'name',
      render: (type: string) => (
        <Tag color="pink">{NOTIFICATION_TYPE[type]}</Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (value: boolean, record: BaseRecord) => {
        const date = new Date(record.endAt).getTime();
        const now = new Date().getTime();
        console.log(now, date, now - date);
        return value ? (
          now - date > 0 && record.type !== 'push_now' ? (
            <Tag color="brown">expired</Tag>
          ) : (
            <Tag color="green">true</Tag>
          )
        ) : (
          <Tag color="red">false</Tag>
        );
      },
    },
    {
      title: 'Deep Link',
      dataIndex: 'deepLink',
      key: 'deepLink',
      render: (value: string) => {
        return value?.includes('web')
          ? decodeURIComponent(`${value?.split('web/').pop()}`)
          : '';
      },
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: 'Countries',
      dataIndex: 'countries',
      key: 'countries',
      render: (countries: any) =>
        countries?.length > 0 ? countries.join(', ') : 'all',
    },
    {
      title: 'Exclude Countries',
      dataIndex: 'excludeCountries',
      key: 'excludeCountries',
      render: (countries: any) =>
        countries?.length > 0 ? countries.join(', ') : 'empty',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: any) => (
        <DateField format="DD/MM/YYYY HH:mm:ss" value={value} />
      ),
    },

    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (value: any) => (
        <DateField format="DD/MM/YYYY HH:mm:ss" value={value} />
      ),
    },
  ];

  const columnUsers = [
    {
      title: 'Id',
      dataIndex: 'value',
      key: 'value',
      render: (id: any) => <Tag color="black">{id}</Tag>,
    },
    {
      title: 'Email',
      dataIndex: 'label',
      key: 'label',
    },
  ];

  const handlePushNow = () => {
    callHandlePushNow(
      {
        url: `${apiUrl}/push-notification/push-now`,
        method: 'post',
        values: {
          id: record?.id,
        },
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      },
      {
        onSuccess: () => {
          open?.({
            type: 'success',
            message: 'Notifications pushed',
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
      },
    );
  };

  return (
    <Show isLoading={isLoading}>
      <Button type="primary" danger onClick={handlePushNow}>
        Push Now
      </Button>
      <div style={{ marginTop: 20 }}>
        <Table
          dataSource={[record || []]}
          rowKey="id"
          columns={columns}
          scroll={{ x: 3500, y: 300, scrollToFirstRowOnChange: true }}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        List Users
        <Table
          dataSource={record?.listUserIds || []}
          rowKey="id"
          columns={columnUsers}
        />
      </div>
    </Show>
  );
};
