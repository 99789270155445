import React, { useState } from 'react';
import { BaseKey, IResourceComponentsProps } from '@refinedev/core';
import {
  useTable,
  List,
  ImageField,
  DateField,
  BooleanField,
} from '@refinedev/antd';
import { Button, Space, Table } from 'antd';
import { BaseRecord } from '@pankod/refine-core';
import { EditOutlined } from '@ant-design/icons';
import { FootballFestCategoryEditModal } from './components/FootballFestCategoryEditModal';

export const FootballFestCategoryList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
    resource: 'football-fest/category',
  });

  const [editModalId, setEditModalId] = useState<BaseKey | null | undefined>(
    null,
  );

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="name" title="Season" />
        <Table.Column
          dataIndex={['iconUrl']}
          title="Icon"
          render={(value: any) => (
            <ImageField style={{ maxWidth: '100px' }} value={value} />
          )}
        />
        <Table.Column
          dataIndex={['startTime']}
          title="Start time"
          render={(value: any) => <DateField value={value} format="LLL" />}
        />
        <Table.Column
          dataIndex={['endTime']}
          title="End time"
          render={(value: any) => <DateField value={value} format="LLL" />}
        />
        <Table.Column
          dataIndex={['isActive']}
          title="Is active"
          render={(value: any) => <BooleanField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                size="small"
                onClick={() => {
                  setEditModalId(record.id);
                }}
              />
            </Space>
          )}
        />
      </Table>
      {typeof editModalId !== 'undefined' && editModalId !== null && (
        <FootballFestCategoryEditModal
          title={`Edit match`}
          open
          onClose={() => {
            setEditModalId(null);
          }}
          recordId={editModalId}
        />
      )}
    </List>
  );
};
