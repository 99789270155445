import React from 'react';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import {
  Show,
  NumberField,
  TagField,
  TextField,
  DateField,
} from '@refinedev/antd';
import { Typography } from 'antd';
import { LuckyWheelRewardType, TypeTagColor } from './constant';

const { Title } = Typography;

export const LuckyWheelRewardShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<{
    id: number;
    name: string;
    amount: number;
    threshold: number;
    type: LuckyWheelRewardType;
    createdAt: string;
    updatedAt: string;
  }>();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Type</Title>
      <TagField
        color={record?.type ? TypeTagColor[record?.type] : undefined}
        value={record?.type}
      />
      <Title level={5}>Amount</Title>
      <NumberField value={record?.amount ?? ''} />
      <Title level={5}>Threshold</Title>
      <NumberField
        value={record?.threshold ?? 0}
        options={{
          style: 'percent',
        }}
      />
      <Title level={5}>Created at</Title>
      <DateField value={record?.createdAt} format="MM/DD/YYYY HH:mm:ss" />
      <Title level={5}>Updated at</Title>
      <DateField value={record?.updatedAt} format="MM/DD/YYYY HH:mm:ss" />
    </Show>
  );
};
