import React, { useCallback, useEffect, useState } from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input, Select, Checkbox } from 'antd';
import dayjs from 'dayjs';

export const UsersEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  const KycOption = {
    options: [
      {
        label: 'Kyc Level 0',
        value: 0,
      },
      {
        label: 'Kyc Level 1',
        value: 1,
      },
      {
        label: 'Kyc Level 2',
        value: 2,
      },
      {
        label: 'Kyc Level 3',
        value: 3,
      },
    ],
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="eKYC Level"
          name={['kycLevel']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select {...KycOption} />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name={'phoneNumber'}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Name"
          name={'name'}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Is Legacy User"
          name={'isLegacyUser'}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Checkbox>Is Legacy User</Checkbox>
        </Form.Item>
      </Form>
    </Edit>
  );
};
