import React from 'react';
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from '@refinedev/core';
import {
  Show,
  NumberField,
  TagField,
  TextField,
  BooleanField,
  DateField,
} from '@refinedev/antd';
import { Typography } from 'antd';
import { MissionTable } from './components/RewardTable';

const { Title } = Typography;

export const LuckyWheelMissionShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Description</Title>
      <TextField value={record?.description} />
      <Title level={5}>Code</Title>
      <TextField value={record?.code} />
      <Title level={5}>Max attempt</Title>
      <NumberField value={record?.maxAttempt ?? ''} />
      <Title level={5}>Is Daily mission</Title>
      <BooleanField value={record?.isDaily} />
      <Title level={5}>Created at</Title>
      <DateField value={record?.createdAt} format="MM/DD/YYYY HH:mm:ss" />
      <Title level={5}>Updated at</Title>
      <DateField value={record?.updatedAt} format="MM/DD/YYYY HH:mm:ss" />
      <Title level={5}>Rewards</Title>
      <MissionTable rewards={record?.rewards} />
    </Show>
  );
};
