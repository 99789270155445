import React from 'react';
import {
  Button,
  Collapse,
  Divider,
  Form,
  FormProps,
  Input,
  Table,
  Tag,
} from 'antd';
import {
  BooleanField,
  DateField,
  NumberField,
  useTable,
} from '@refinedev/antd';
import {
  CrudFilters,
  useApiUrl,
  useCustom,
  useTranslate,
} from '@refinedev/core';
import { DateFieldTimezone } from '../../components/table/';
import { SearchOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export const ShowReport: React.FC<any> = ({ id }) => {
  const translate = useTranslate();
  const { tableProps: listRequestedProps, searchFormProps } = useTable({
    syncWithLocation: true,
    resource: `lottery/result-detail/${id}`,
    onSearch: (params: any) => {
      let filters: CrudFilters = [];
      const { email } = params;

      filters = [
        {
          field: 'email',
          operator: 'eq',
          value: email,
        },
      ];

      return filters;
    },
  });

  const { tableProps: resultOverall } = useTable({
    syncWithLocation: true,
    resource: `lottery/result-overall/${id}`,
  });

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <div>
      <br />
      <Tag color="blue" style={{ fontSize: 16, padding: 4 }}>
        <div>Overall of Draw #{id}</div>
      </Tag>
      <Tag color="black" style={{ fontSize: 16, padding: 4 }}>
        <div>
          Total &nbsp;
          {resultOverall?.dataSource
            ? resultOverall?.dataSource.reduce(
                (sum, item) => sum + item.count,
                0,
              )
            : 0}
          &nbsp;tickets
        </div>
      </Tag>
      <Table {...resultOverall} rowKey="prizeType" sticky={{ offsetHeader: 0 }}>
        <Table.Column dataIndex="prizeType" title={translate('prizeType')} />
        <Table.Column dataIndex="count" title={translate('Count')} />
      </Table>
      <Divider />
      <Tag color="red" style={{ fontSize: 16, padding: 4 }}>
        Detail results
      </Tag>
      <Collapse
        defaultActiveKey={['1']}
        onChange={onChange}
        style={{ marginBottom: 16 }}
      >
        <Panel header="Filter" key="1">
          <Filter formProps={searchFormProps} />
        </Panel>
      </Collapse>
      <Table {...listRequestedProps} rowKey="id" sticky={{ offsetHeader: 0 }}>
        <Table.Column
          dataIndex="jackpotUserTicketId"
          title={translate('TicketId')}
        />
        <Table.Column
          dataIndex="jackpotUserTicket"
          title={translate('Email')}
          render={(value: any) => <Tag color="blue">{value.user.email}</Tag>}
          width={300}
        />
        <Table.Column
          dataIndex="jackpotDrawId"
          title={translate('DrawId')}
          render={(value: any) => <Tag color="blue">{value}</Tag>}
        />
        <Table.Column
          dataIndex="prizeType"
          title={translate('prizeType')}
          render={(value: any) => <Tag color="black">{value}</Tag>}
        />
        <Table.Column
          dataIndex="isReward"
          title={translate('isReward')}
          render={(value: any) =>
            value ? <Tag color="green">TRUE</Tag> : <Tag color="red">FALSE</Tag>
          }
        />

        <Table.Column
          dataIndex="metadata"
          title={translate('Jackpot Number')}
          render={(value: any) => {
            return <Tag color="red">{value.jackpotNumber}</Tag>;
          }}
        />

        <Table.Column
          dataIndex="metadata"
          title={translate('Wining Number')}
          render={(value: any) => (
            <>
              {value.matchingNumbers.map((i: any) => (
                <Tag
                  color="blue"
                  style={{
                    fontWeight: 700,
                    width: '30px',
                    textAlign: 'center',
                  }}
                >
                  {i}
                </Tag>
              ))}
            </>
          )}
        />
        <Table.Column
          dataIndex="rewardedAt"
          title={'RewardedAt'}
          render={(value: any) => <DateFieldTimezone value={value} />}
        />
      </Table>
    </div>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Email" name="email">
        <Input
          placeholder="Email"
          prefix={<SearchOutlined />}
          type="string"
          allowClear
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
