import { HttpError } from '@refinedev/core';
import { ACCESS_TOKEN_KEY } from 'authProvider';
import axios, { AxiosRequestConfig } from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request: any) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (request.headers) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  } else {
    request.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  },
);

export { axiosInstance };
