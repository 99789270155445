import React from 'react';
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from '@refinedev/core';
import {
  Show,
  NumberField,
  TagField,
  TextField,
  BooleanField,
} from '@refinedev/antd';
import { Typography } from 'antd';

const { Title } = Typography;

export const EarnWidgetShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>ID</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Earn Campaign Name</Title>
      <TextField value={record?.earnCampaignName} />
      <Title level={5}>Language Code</Title>
      <TextField value={record?.languageCode} />
      <Title level={5}>Description</Title>
      <TextField value={record?.description} />
      <Title level={5}>Is Active</Title>
      <BooleanField value={record?.isActive} />

      <Title level={5}>Action Flow</Title>
      {record?.actionFlow?.map((item: any) => (
        <>
          <Typography.Text code={true} editable={false}>
            {item.step}: {item.description}
          </Typography.Text>
          <br />
        </>
      ))}
    </Show>
  );
};
