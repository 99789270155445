import { Tag } from 'antd';

interface HyperLinkFieldProps {
  link: string;
  value: string;
  color?: string;
  linkOnly?: boolean;
}

export const HyperLinkField: React.FC<HyperLinkFieldProps> = (
  props: HyperLinkFieldProps,
) => {
  return (
    <Tag color={props.color}>
      <a
        target="_blank"
        href={props.linkOnly ? props.link : props.link + props.value}
      >
        {props.value}
      </a>
    </Tag>
  );
};
