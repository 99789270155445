import React from 'react';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Show, NumberField, TextField, DateField } from '@refinedev/antd';
import { Col, Collapse, Row, Typography } from 'antd';

const { Title } = Typography;

export const OtaRewardBatchShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>ID</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Status</Title>
      <TextField value={record?.status} />
      <Title level={5}>Created At</Title>
      <DateField value={record?.createdAt} />
      <Title level={5}>Updated At</Title>
      <DateField value={record?.updatedAt} />
      <Title level={5}>Reviewed At</Title>
      <DateField value={record?.reviewedAt} />
      <Collapse>
        <Collapse.Panel header="Ota Reward Item" key="1">
          <Row>
            <Col span={1}> ID</Col>
            <Col span={2}> User Id</Col>
            <Col span={6}> User Email</Col>
            <Col span={2}> Amount</Col>
            <Col span={2}> Reward Type</Col>
            <Col span={2}> Reward Reason</Col>
            <Col span={2}> Status</Col>
          </Row>
          {record?.otaRewardItem.map((item: any) => (
            <Row>
              <Col span={1}> {item?.id}</Col>
              <Col span={2}> {item?.userId}</Col>
              <Col span={6}> {item?.userEmail}</Col>
              <Col span={2}> {item?.amount}</Col>
              <Col span={2}> {item?.rewardType}</Col>
              <Col span={2}> {item?.rewardReason}</Col>
              <Col span={2}> {item?.status}</Col>
            </Row>
          ))}
        </Collapse.Panel>
      </Collapse>
    </Show>
  );
};
