import React, { useCallback, useEffect, useState } from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input, Select, Checkbox } from 'antd';
import dayjs from 'dayjs';

import {
  HYPERVERGE_STATUS,
  IMOTA_KYC2_STATUS,
  REASON_MESSAGE_LANGUAGE,
  REASON_LIST,
} from '../../constants';

export const HypervergeEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  const acceptedStatus = formProps?.initialValues?.statusReview === 'accepted';

  const hypervergeStatus = {
    options: HYPERVERGE_STATUS,
  };

  const imotaStatus = {
    options: IMOTA_KYC2_STATUS,
  };

  interface PriceInputProps {
    value?: { en: string; vn: string };
    onChange?: (value: object) => void;
  }

  const ReasonInput: React.FC<PriceInputProps> = ({ value, onChange }) => {
    const [en, setEn] = useState<string>(value?.en ?? '');
    const [vn, setVn] = useState<string>(value?.vn ?? '');
    const [reason, setReason] = useState<any>({ en, vn });

    useEffect(() => {
      triggerChange();
    }, [reason]);

    const triggerChange = useCallback(() => {
      onChange?.(reason as object);
    }, [reason]);

    const onChangeEn = (e: React.ChangeEvent<HTMLInputElement>) => {
      const language = e.target.value;
      setEn(language);
      reason.en = language;
      setReason(reason);
    };

    const onChangeVn = (e: React.ChangeEvent<HTMLInputElement>) => {
      const language = e.target.value;
      setVn(language);
      reason.vn = language;
      setReason(reason);
    };

    const handleChange = (e: string) => {
      const value = REASON_LIST[`${e}`];
      setVn(value.vn);
      setEn(value.en);
      setReason({ en: value.en, vn: value.vn });
    };

    return (
      <span>
        <label>- Lựa chọn theo Template</label>
        <Select
          style={{ marginBottom: 16 }}
          onChange={handleChange}
          options={REASON_MESSAGE_LANGUAGE}
          disabled={acceptedStatus}
        />
        <label>- EN</label>
        <Input
          style={{ marginBottom: 16 }}
          type="text"
          value={en}
          onChange={onChangeEn}
          disabled={acceptedStatus}
        />
        <label>- VN</label>
        <Input
          type="text"
          value={vn}
          onChange={onChangeVn}
          disabled={acceptedStatus}
        />
      </span>
    );
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Id"
          name={['id']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="User"
          name={'userId'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>

        <Form.Item
          label="Status"
          name={['status']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...hypervergeStatus} />
        </Form.Item>
        <Form.Item
          label="Status Review"
          name={['statusReview']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...imotaStatus} disabled={acceptedStatus} />
        </Form.Item>

        <Form.Item
          label="Reason"
          name={['reason']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReasonInput />
        </Form.Item>
        <Form.Item
          label="Is Verify  -  Trạng thái này dùng để kiểm tra kết quả với Hyperverge: True là đã verify. Nếu set False hệ thống sẽ chạy check lại với Hyperverge sau mỗi 1 phút"
          valuePropName="checked"
          name={['isVerify']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Is Verify</Checkbox>
        </Form.Item>
        <Form.Item
          label="Is Deleted - Dùng để mở lại tính năng KYC Lv2 cho User, True là xóa (mở lại), False là không"
          valuePropName="checked"
          name={['isDeleted']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Is Deleted</Checkbox>
        </Form.Item>
        <Form.Item
          label="Created At"
          name={['createdAt']}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <Input readOnly disabled />
        </Form.Item>
      </Form>
    </Edit>
  );
};
