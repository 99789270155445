import React from 'react';
import { IResourceComponentsProps, useApiUrl } from '@refinedev/core';
import { Create, useForm, useSelect } from '@refinedev/antd';
import {
  Form,
  Input,
  Checkbox,
  DatePicker,
  Select,
  Upload,
  Typography,
  Button,
} from 'antd';
import dayjs from 'dayjs';
import { getFile } from '../../utils/upload-file';
import {
  EARN_CAMPAIGN_CURRENCY_UNIT_OPTION,
  EARN_CAMPAIGN_PLATFORM_OPTION,
  EARN_CAMPAIGN_TYPE_OPTION,
} from '../../constants';

const { Text, Link } = Typography;

export const EarnCampaignCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: earnNetworkSelectProps } = useSelect({
    resource: 'earn-network',
    optionLabel: 'name',
  });

  const { selectProps: earnCategorySelectProps } = useSelect({
    resource: 'earn-category',
    optionLabel: 'name',
  });

  const earnCampaignTypes = EARN_CAMPAIGN_TYPE_OPTION;

  const earnCampaignCurrencyUnits = EARN_CAMPAIGN_CURRENCY_UNIT_OPTION;

  const earnCampaignPlatforms = EARN_CAMPAIGN_PLATFORM_OPTION;

  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Earn Network"
          name={['earnNetworkId']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...earnNetworkSelectProps} />
        </Form.Item>
        <Form.Item
          label="Name"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Icon">
          <Form.Item
            name="icon"
            getValueFromEvent={getFile}
            noStyle
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/medias/image`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              maxCount={1}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Slide Image">
          <Form.Item
            name="slideImage"
            getValueFromEvent={getFile}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/medias/image`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              maxCount={1}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Background Image">
          <Form.Item
            name="backgroundImage"
            getValueFromEvent={getFile}
            noStyle
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/medias/image`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              maxCount={1}
              multiple
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Type"
          name={['type']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...earnCampaignTypes} />
        </Form.Item>
        <Form.Item
          label="Commission Fee (Số tiền thưởng Network trả cho Imota)"
          name={['commissionFee']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Currency Unit (Đơn vị tiền)"
          name={['currencyUnit']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...earnCampaignCurrencyUnits} />
        </Form.Item>
        <Form.Item
          label={
            <div>
              <p style={{ fontSize: '16px' }}>Product Link (Encrypted)</p>
              <p>
                * AccessTrade: lưu ý chỉ sử dụng link sản phẩm có định dạng:
                http://go.isclix.com hoặc https://fast.accesstrade.com.vn.
                &nbsp;
                <span style={{ color: 'orange' }}>
                  Không sử dụng Link rút gọn
                </span>
              </p>
              <p>
                * Adflex: lưu ý chỉ sử dụng Link đã mã hóa có định dạng
                https://aff.xtimize.com/v2/...... &nbsp;
                <span style={{ color: 'orange' }}>
                  không sử dụng Tracking link hoặc Link rút gọn
                </span>
              </p>
            </div>
          }
          name={['productLink']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Platform"
          name={['platform']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...earnCampaignPlatforms} />
        </Form.Item>
        <Form.Item
          label="Is Slide"
          valuePropName="checked"
          name={['isSlide']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Checkbox>Is Slide</Checkbox>
        </Form.Item>
        <Form.Item
          label="Is Active"
          valuePropName="checked"
          name={['isActive']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
        <Form.Item
          label="Start Date"
          name={['startDate']}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="End Date"
          name={['endDate']}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label={
            <div>
              <p style={{ fontSize: '16px' }}>Offer ID</p>
              <p>
                <span style={{ color: 'orange' }}>ID Campaign của Network</span>
              </p>
            </div>
          }
          name={['offerId']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <div>
              <p style={{ fontSize: '16px' }}>Amount</p>
              <p>
                <span style={{ color: 'orange' }}>
                  Tổng số lượt thực hiện của Campaign
                </span>
              </p>
            </div>
          }
          name={['amount']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="1" />
        </Form.Item>

        <Form.Item
          label="Hashtag (Separate with comma(,))"
          name={['hashtag']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="Red,Green,Blue Sky" />
        </Form.Item>

        <Form.Item
          label={
            <div>
              <p style={{ fontSize: '16px' }}>Country Code Allow</p>
              <p>
                List of ISO 639-1 codes &nbsp;
                <a href="https://localizely.com/iso-639-1-list/">
                  https://localizely.com/iso-639-1-list/
                </a>
              </p>
            </div>
          }
          name={['countryCodeAllow']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="vi,en" />
        </Form.Item>
        <Form.Item
          label={
            <div>
              <p style={{ fontSize: '16px' }}>Country Code Deny</p>
              <p>
                List of ISO 639-1 codes &nbsp;
                <a href="https://localizely.com/iso-639-1-list/">
                  https://localizely.com/iso-639-1-list/
                </a>
              </p>
            </div>
          }
          name={['countryCodeDeny']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="vi,en" />
        </Form.Item>
        <Form.Item
          label="Sort"
          name={['sort']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="1" />
        </Form.Item>

        <Form.Item
          label="Category"
          name={['categoryIds']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select {...earnCategorySelectProps} mode="multiple" />
        </Form.Item>
      </Form>
      <Text type="danger">- Tạo Widget Ngôn ngữ cho Campaign</Text>
      <br />
      <Text type="danger">- Tạo thêm Campaign Reward</Text>
    </Create>
  );
};
