import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import {
  Form,
  Input,
  Checkbox,
  Select,
  Button,
  Space,
  Divider,
  Tag,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;

export const EarnWidgetEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const earnWidgetData = queryResult?.data?.data;

  const { selectProps: earnCampaignSelectProps } = useSelect({
    resource: 'earn-campaign',
    optionLabel: 'name',
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="ID"
          name={['id']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Earn Campaign"
          name={['earnCampaignId']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...earnCampaignSelectProps} />
        </Form.Item>
        <Form.Item
          label="Language Code"
          name={['languageCode']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={['description']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Is Active"
          valuePropName="checked"
          name={['isActive']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>

        <Divider orientation="left" orientationMargin="0">
          Action Flow Steps
        </Divider>

        <Form.List name="actionFlow">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space.Compact
                  key={key}
                  style={{ display: 'flex', marginBottom: 0 }}
                  block
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'step']}
                    rules={[{ required: false }]}
                  >
                    <Tag>{`Step ${index + 1}`}</Tag>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    rules={[{ required: true, message: 'Missing description' }]}
                    style={{ width: '100%' }}
                  >
                    <TextArea
                      placeholder="User must do something..."
                      autoSize
                      rows={2}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space.Compact>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add step
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Edit>
  );
};
