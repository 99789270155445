import React from 'react';
import { IResourceComponentsProps, useShow, useOne } from '@refinedev/core';
import {
  Show,
  NumberField,
  TextField,
  DateField,
  MarkdownField,
  EmailField,
} from '@refinedev/antd';
import { Space, Typography } from 'antd';

const { Title } = Typography;

export const EarnUserHistoryShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: userData, isLoading: userIsLoading } = useOne({
    resource: 'users',
    id: record?.userId || '',
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>ID</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>User</Title>
      {userIsLoading ? <>Loading...</> : <>{userData?.data?.name}</>}
      <Title level={5}>User Email</Title>
      <EmailField value={record?.email} />
      <Title level={5}>Transaction</Title>
      <TextField value={record?.transaction} />
      <Title level={5}>Platform</Title>
      <TextField value={record?.platform} />
      <Title level={5}>Platform Os Version</Title>
      <NumberField value={record?.platformOsVersion ?? ''} />
      <Title level={5}>Mobile App Version</Title>
      <DateField value={record?.mobileAppVersion} />
      <Title level={5}>Browser</Title>
      <MarkdownField value={record?.browser} />
      <Title level={5}>IP</Title>
      <TextField value={record?.ip} />
      <Title level={5}>State Campaign</Title>
      <TextField value={record?.stateCampaign} />
      <Title level={5}>State Campaign At</Title>
      <DateField value={record?.stateCampaignAt} />
      <Title level={5}>Earn Campaign Name</Title>
      <TextField value={record?.earnCampaignName} />
      <Title level={5}>Action Flow</Title>
      <Space>
        {record?.actionFlowDetail?.map((item: any) => (
          <>
            <Typography.Text code={true} editable={false}>
              {item.step}:{item.isDone ? 'Done' : 'Not Done'}
            </Typography.Text>
            <br />
          </>
        ))}
      </Space>
    </Show>
  );
};
