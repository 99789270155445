import React from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  CrudFilters,
} from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  EmailField,
} from '@refinedev/antd';
import {
  Table,
  Space,
  FormProps,
  Button,
  Form,
  Select,
  Collapse,
  Tag,
  Input,
} from 'antd';

import { DateFieldTimezone, HyperLinkField } from '../../components/table/';
import {
  HYPERVERGE_STATUS_COLOR,
  USERS_LINK,
  EARN_CLAIM_HISTORY_STATUS,
} from '../../constants';
import { SearchOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export const EarnClaimHistoryList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch: (params: any) => {
      let filters: CrudFilters = [];
      const { status, search } = params;

      filters = [
        {
          field: 'status',
          operator: 'eq',
          value: status,
        },
        {
          field: 'search',
          operator: 'eq',
          value: search,
        },
      ];

      return filters;
    },
  });

  return (
    <div>
      <Collapse defaultActiveKey={['0']} style={{ marginBottom: 16 }}>
        <Panel header="Filter" key="1">
          <Filter formProps={searchFormProps} />
        </Panel>
      </Collapse>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="userId"
            title="User ID"
            render={(value: string) => (
              <HyperLinkField value={value} link={USERS_LINK} />
            )}
          />
          <Table.Column
            dataIndex={['email']}
            title="User Email"
            render={(value: any) => <EmailField value={value} />}
          />
          <Table.Column dataIndex="amount" title="Amount" />
          <Table.Column dataIndex="busdAmount" title="BUSD Amount" />
          <Table.Column dataIndex="address" title="Address" />
          <Table.Column dataIndex="adminNote" title="Admin Note" />
          <Table.Column dataIndex="paymentNote" title="Payment Note" />
          <Table.Column
            dataIndex="status"
            title="Status"
            render={(value) => {
              const color = HYPERVERGE_STATUS_COLOR[`${value}`];
              return <Tag color={color}>{value}</Tag>;
            }}
          />
          <Table.Column
            dataIndex={['createdAt']}
            title="Created At"
            render={(value: any) => <DateFieldTimezone value={value} />}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                {record.status === 'new' && (
                  <EditButton hideText size="small" recordItemId={record.id} />
                )}
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </div>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Status" name="status">
        <Select
          allowClear
          options={EARN_CLAIM_HISTORY_STATUS}
          placeholder="Status"
        />
      </Form.Item>
      <Form.Item label="Search" name="search">
        <Input placeholder="Search user email" prefix={<SearchOutlined />} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
