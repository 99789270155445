import React from 'react';
import { IResourceComponentsProps, useApiUrl } from '@refinedev/core';
import { Create, useForm, useSelect, getValueFromEvent } from '@refinedev/antd';
import { Form, Input, Select, DatePicker, Upload, Checkbox } from 'antd';
import dayjs from 'dayjs';

import { DOCUMENT_TYPE_OPTION, GENDER_OPTION } from '../../constants';
import { ICreateKYCManual } from '../../interfaces';

export const KycLv2Create: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ICreateKYCManual>();
  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');

  const { selectProps: userSelectProps } = useSelect({
    resource: 'users',
    optionLabel: 'email',
  });

  const { selectProps: countrySelectProps } = useSelect({
    resource: 'countries',
    optionLabel: 'name',
    optionValue: 'countryId',
  });

  countrySelectProps.options?.push({
    label: 'Nigeria',
    value: 'nga',
  });

  countrySelectProps.defaultValue = { value: 'nga', label: 'Nigeria' };

  const documentOption = {
    options: DOCUMENT_TYPE_OPTION,
    defaultValue: DOCUMENT_TYPE_OPTION[0],
  };

  const genderOption = {
    options: GENDER_OPTION,
    defaultValue: GENDER_OPTION[0],
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Country"
          name={['country']}
          initialValue={'nga'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...countrySelectProps} />
        </Form.Item>

        <Form.Item
          label="Document Type"
          name={['documentType']}
          initialValue={DOCUMENT_TYPE_OPTION[0].value}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...documentOption} />
        </Form.Item>

        <Form.Item
          label="User"
          name={'userId'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userSelectProps} />
        </Form.Item>

        <Form.Item
          label="ID Number"
          name={['identityNumber']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Passport Number"
          name={['passportNumber']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Driver's License"
          name={['driverLicenseNumber']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Full Name"
          name={['fullName']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Image FrontSide">
          <Form.Item
            name="imageFront"
            getValueFromEvent={getValueFromEvent}
            valuePropName="fileList"
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/medias/image`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              // listType="picture"
              maxCount={1}
              multiple
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Image BackSide">
          <Form.Item
            name="imageBack"
            getValueFromEvent={getValueFromEvent}
            valuePropName="fileList"
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/medias/image`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              // listType="picture"
              maxCount={1}
              multiple
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Image Face">
          <Form.Item
            name="imageFace"
            getValueFromEvent={getValueFromEvent}
            valuePropName="fileList"
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/medias/image`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              // listType="picture"
              maxCount={1}
              multiple
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Gender"
          name={['gender']}
          initialValue={GENDER_OPTION[0].value}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select {...genderOption} />
        </Form.Item>
        <Form.Item
          label="Birthday DD-MM-YYYY"
          name={['birthday']}
          rules={[
            {
              required: false,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker format={'DD-MM-YYYY'} />
        </Form.Item>
        <Form.Item
          label="Address"
          name={['address']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Is Active"
          valuePropName="checked"
          initialValue={true}
          name={['isActive']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
        <Form.Item
          label="Description"
          name={['description']}
          initialValue={'isManualCheck'}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
