import { useForm } from '@refinedev/antd';
import { BaseKey } from '@refinedev/core';
import { Checkbox, Form, InputNumber, Modal, ModalProps } from 'antd';

export const FootballFestMatchEditModal = ({
  recordId,
  onClose,
  ...props
}: ModalProps & {
  recordId: BaseKey;
  onClose: () => void;
}) => {
  const { formProps, form } = useForm({
    action: 'edit',
    resource: 'football-fest/match',
    id: recordId,
    onMutationSuccess: () => {
      onClose();
    },
    successNotification: (data) => {
      return {
        message: `Updated match ${data?.data.id}`,
        type: 'success',
      };
    },
  });

  return (
    <Modal
      {...props}
      onOk={() => form.submit()}
      okText="Save"
      onCancel={onClose}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Can match have draw result?"
          valuePropName="checked"
          name={['canResultBeDraw']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item label="Display priority" name={['displayPriority']}>
          <InputNumber min={0} defaultValue={0} />
        </Form.Item>
        <Form.Item
          label="Is active"
          name={['isActive']}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};
