import React from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  CrudFilters,
} from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  BooleanField,
} from '@refinedev/antd';
import {
  Table,
  Space,
  FormProps,
  Button,
  Form,
  Input,
  Select,
  Collapse,
  Tag,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { DateFieldTimezone, HyperLinkField } from '../../components/table/';
import {
  HYPERVERGE_STATUS_COLOR,
  IMOTA_KYC2_STATUS_COLOR,
  HYPERVERGE_LINK,
  USERS_LINK,
  IMOTA_KYC2_STATUS,
  HYPERVERGE_STATUS,
} from '../../constants';

const { Panel } = Collapse;

export const HypervergeList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch: (params: any) => {
      let filters: CrudFilters = [];
      const { q, status, statusReview } = params;

      filters = [
        {
          field: 'q',
          operator: 'eq',
          value: q,
        },
        {
          field: 'status',
          operator: 'eq',
          value: status,
        },
        {
          field: 'statusReview',
          operator: 'eq',
          value: statusReview,
        },
      ];

      return filters;
    },
  });

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <div>
      <Collapse
        defaultActiveKey={['0']}
        onChange={onChange}
        style={{ marginBottom: 16 }}
      >
        <Panel header="Filter" key="1">
          <Filter formProps={searchFormProps} />
        </Panel>
      </Collapse>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="userId"
            title="User ID"
            render={(value: string) => (
              <HyperLinkField value={value} link={USERS_LINK} />
            )}
          />
          <Table.Column
            dataIndex="user"
            title="Email"
            render={(value: any) => <Tag color="geekblue">{value.email}</Tag>}
          />
          <Table.Column
            dataIndex="transactionId"
            title="Transaction"
            render={(value: string) => (
              <HyperLinkField value={value} link={HYPERVERGE_LINK} />
            )}
          />
          <Table.Column dataIndex="country" title="Country" />
          <Table.Column dataIndex="documentType" title="Document Type" />
          <Table.Column
            dataIndex="statusReview"
            title="Imota Status"
            render={(value) => {
              const color = IMOTA_KYC2_STATUS_COLOR[`${value}`];
              return <Tag color={color}>{value}</Tag>;
            }}
          />
          <Table.Column
            dataIndex="status"
            title="Hyperverge Status"
            render={(value) => {
              const color = HYPERVERGE_STATUS_COLOR[`${value}`];
              return <Tag color={color}>{value}</Tag>;
            }}
          />
          <Table.Column
            dataIndex={['isVerify']}
            title="Is Verify With HV"
            render={(value: any) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex={['isDeleted']}
            title="Is Deleted"
            render={(value: any) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex={['createdAt']}
            title="Created At"
            render={(value: any) => <DateFieldTimezone value={value} />}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </div>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="User ID, Email, TransactionID, etc."
          prefix={<SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Hyperverge Status" name="status">
        <Select
          allowClear
          options={HYPERVERGE_STATUS}
          placeholder="Hyperverge Status"
        />
      </Form.Item>
      <Form.Item label="Imota Status" name="statusReview">
        <Select
          allowClear
          options={IMOTA_KYC2_STATUS}
          placeholder="Imota Status"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
