import React from 'react';
import { IResourceComponentsProps, useApiUrl } from '@refinedev/core';
import { Edit, ImageField, useForm } from '@refinedev/antd';
import { Form, Input, Checkbox, Upload } from 'antd';
import { getFile } from '../../utils/upload-file';

export const EarnCategoryEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const earnCategoryData = queryResult?.data?.data;
  const record = earnCategoryData;
  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="ID"
          name={['id']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Icon">
          <ImageField style={{ maxWidth: 200 }} value={record?.icon} />
          <Form.Item
            name="icon"
            getValueFromEvent={getFile}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              listType="picture"
              name="file"
              action={`${apiUrl}/medias/image`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              maxCount={1}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Description"
          name={['description']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Sort"
          name={['sort']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Is Active"
          valuePropName="checked"
          name={['isActive']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
      </Form>
    </Edit>
  );
};
