import React, { useEffect, useState } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useTranslate,
} from '@refinedev/core';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Checkbox, DatePicker, Select, Upload } from 'antd';
import dayjs from 'dayjs';
import {
  COUNTRY_CODE_OPTION,
  NOTIFICATION_DEEP_LINK_OPTION,
  NOTIFICATION_HOURS_OPTION,
  NOTIFICATION_MINUTE_OPTION,
  NOTIFICATION_PLATFORM_OPTION,
  NOTIFICATION_TYPE_OPTION,
} from '../../constants';
import { getDataFile } from '../../utils/upload-file';

export const PushNotificationEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');

  const { selectProps: userSelectProps } = useSelect({
    resource: 'users',
    optionLabel: 'email',
    optionValue: 'id',
  });

  const { formProps, saveButtonProps, queryResult } = useForm();
  if (formProps?.initialValues?.endAt) {
    formProps.initialValues.endAt = formProps?.initialValues?.endAt.slice(
      0,
      -1,
    );
  }

  const disabledDate = (current: any) => {
    return current && current < dayjs().startOf('day');
  };

  const pushNotificationData = queryResult?.data?.data;

  const [isHiddenLinkWeb, setIsHiddenLinkWeb] = useState<boolean>(
    !pushNotificationData?.deepLink?.includes('web'),
  );
  const [isHidden, setIsHidden] = useState<boolean>(
    pushNotificationData?.type === 'push_now',
  );

  useEffect(() => {
    setIsHidden(pushNotificationData?.type === 'push_now');
    setIsHiddenLinkWeb(!pushNotificationData?.deepLink?.includes('web'));
  }, [pushNotificationData]);
  const onChangeType = (value: string) => {
    if (value === 'push_now') {
      setIsHidden(true);
    } else setIsHidden(false);
  };

  const onChangeDeepLink = (value: string) => {
    console.log(value);
    if (value !== 'web') {
      setIsHiddenLinkWeb(true);
    } else setIsHiddenLinkWeb(false);
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={translate('id')}
          name={['id']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label={translate('name')}
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('vnTitle')}
          name={['vnTitle']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('vnMessage')}
          name={['vnMessage']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('enTitle')}
          name={['enTitle']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('enMessage')}
          name={['enMessage']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('type')}
          name={['type']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {/* <Input /> */}
          <Select
            options={NOTIFICATION_TYPE_OPTION}
            defaultValue={NOTIFICATION_TYPE_OPTION[0].value}
            onChange={onChangeType}
          />
        </Form.Item>
        <Form.Item
          hidden={isHidden}
          label={translate('hour')}
          name={['hour']}
          rules={[
            {
              required: !isHidden,
            },
          ]}
        >
          <Select options={NOTIFICATION_HOURS_OPTION} />
        </Form.Item>
        <Form.Item
          hidden={isHidden}
          label={translate('minute')}
          name={['minute']}
          rules={[
            {
              required: !isHidden,
            },
          ]}
        >
          <Select options={NOTIFICATION_MINUTE_OPTION} />
        </Form.Item>
        <Form.Item
          hidden={isHidden}
          label={translate('startAt')}
          name={['startAt']}
          rules={[
            {
              required: !isHidden,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker format={'DD/MM/YYYY'} disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item
          hidden={isHidden}
          label={translate('endAt')}
          name={['endAt']}
          rules={[
            {
              required: !isHidden,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker format={'DD/MM/YYYY'} disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item
          label={translate('Status')}
          valuePropName="checked"
          name={['isActive']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
        <Form.Item
          label={translate('DeepLink')}
          name={['deepLink']}
          getValueProps={(value) => ({
            value: value?.includes('web') ? 'web' : value ? value : 'empty',
          })}
        >
          <Select
            options={NOTIFICATION_DEEP_LINK_OPTION}
            onChange={onChangeDeepLink}
          />
        </Form.Item>
        <Form.Item
          hidden={isHiddenLinkWeb}
          label={translate('Link Web')}
          name={['linkWeb']}
          rules={[
            {
              required: !isHiddenLinkWeb,
            },
          ]}
          initialValue={
            pushNotificationData?.deepLink?.includes('web')
              ? decodeURIComponent(
                  pushNotificationData?.deepLink?.split('web/').pop(),
                )
              : ''
          }
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate('platform')}
          name={['platform']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={NOTIFICATION_PLATFORM_OPTION}
            defaultValue={NOTIFICATION_PLATFORM_OPTION[0]}
          />
        </Form.Item>
        <Form.Item label={translate('Countries')} name={['countries']}>
          <Select
            options={COUNTRY_CODE_OPTION}
            filterOption={true}
            mode="multiple"
            allowClear
            optionFilterProp={'label'}
          />
        </Form.Item>
        <Form.Item
          label={translate('Exclude Countries')}
          name={['excludeCountries']}
        >
          <Select
            options={COUNTRY_CODE_OPTION}
            filterOption={true}
            mode="multiple"
            allowClear
            optionFilterProp={'label'}
          />
        </Form.Item>
        <div
          style={{
            backgroundColor: '#f1f1f1',
            padding: '20px',
            border: '1px solid #d9d9d9',
            borderRadius: '4px',
          }}
        >
          <Form.Item
            label="List User ID"
            name={['listUserIds']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              {...userSelectProps}
              filterOption={true}
              mode="multiple"
              allowClear
              optionFilterProp={'label'}
            />
          </Form.Item>
          <Form.Item label="or CSV File">
            <Form.Item
              name="csvListUserIds"
              getValueFromEvent={getDataFile}
              noStyle
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Upload.Dragger
                listType="picture"
                name="file"
                action={`${apiUrl}/push-notification/upload`}
                headers={{
                  Authorization: `Bearer ${token}`,
                }}
                maxCount={1}
              >
                <p className="ant-upload-text">
                  Drag & drop a file in this area
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </div>
      </Form>
    </Edit>
  );
};
