import React from 'react';
import { IResourceComponentsProps, useExport } from '@refinedev/core';
import { useTable, List, DateField, ExportButton } from '@refinedev/antd';
import { Table, DatePicker, DatePickerProps, Tag } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/vi_VN';
import 'dayjs/locale/vi';

export const Top10EnergyStoneList: React.FC<IResourceComponentsProps> = () => {
  const defaultDate =
    dayjs().day() === 0
      ? dayjs().subtract(2, 'day')
      : dayjs().subtract(1, 'day');
  const { tableProps, setFilters } = useTable({
    syncWithLocation: true,
    resource: 'lucky-wheel-reward/top-10-energy-stone',
    filters: {
      initial: [{ field: 'date', operator: 'eq', value: defaultDate }],
    },
  });
  const onChange: DatePickerProps['onChange'] = (date) => {
    setFilters([{ field: 'date', operator: 'eq', value: date }]);
  };
  const { triggerExport, isLoading: exportLoading } = useExport({
    filters: [{ field: 'date', operator: 'eq', value: defaultDate }],
  });

  const weekFormat = 'DD/MM';
  const customWeekStartEndFormat: DatePickerProps['format'] = (value: any) => {
    return `${dayjs(value)
      .locale('vi-vn')
      .startOf('week')
      .format(weekFormat)} ~ ${dayjs(value)
      .locale('vi-vn')
      .endOf('week')
      .format(weekFormat)}`;
  };

  return (
    <List
    // headerButtons={
    //   <ExportButton onClick={triggerExport} loading={exportLoading} />
    // }
    >
      <DatePicker
        onChange={onChange}
        picker="week"
        style={{ borderColor: 'red', width: '30%', marginBottom: '10px' }}
        defaultValue={defaultDate}
        format={customWeekStartEndFormat}
        locale={locale}
      />
      <br />
      <Table {...tableProps} rowKey="userId">
        <Table.Column dataIndex="userId" title="userId" />
        <Table.Column
          dataIndex="user"
          title="Name"
          render={(value) => value.name}
        />
        <Table.Column
          dataIndex="user"
          title="Name"
          render={(value) => <Tag color="purple">{value.email}</Tag>}
        />
        <Table.Column
          dataIndex="amount"
          title="Amount"
          render={(value) => <Tag color="blue">{Math.abs(value)}</Tag>}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Created At"
          render={(value) => (
            <DateField value={value} format="DD/MM/YYYY HH:mm" />
          )}
          sorter
        />
      </Table>
    </List>
  );
};
