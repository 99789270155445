import React from 'react';
import { IResourceComponentsProps, useApiUrl } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, Upload } from 'antd';
import { getFile } from '../../utils/upload-file';

export const UserDataMigrationCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps } = useForm();

  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="User ID"
          name={['userId']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="CSV File">
          <Form.Item
            name="csvPath"
            getValueFromEvent={getFile}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              listType="picture"
              name="file"
              action={`${apiUrl}/user-data-migration/upload`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              maxCount={1}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};
