import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { DatePicker, Form, Select } from 'antd';
import { PartnerRoles } from './constant/partnerRole';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';

export const PartnerCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form } = useForm();

  const { selectProps: userSelectProps } = useSelect({
    resource: 'users',
    optionLabel: 'email',
    optionValue: 'email',
  });

  const RoleSelect = Object.values(PartnerRoles).map((role) => ({
    label: capitalize(role),
    value: role,
  }));

  const role = form.getFieldValue('role');

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Partner email"
          name={['email']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userSelectProps} />
        </Form.Item>
        <Form.Item
          label="Role"
          name={['role']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={RoleSelect} />
        </Form.Item>
        {role === PartnerRoles.AMBASSADOR && (
          <Form.Item
            label="Joined Date"
            name={['joinedAt']}
            initialValue={dayjs()}
          >
            <DatePicker showTime />
          </Form.Item>
        )}
      </Form>
    </Create>
  );
};
