export const getFile = (info: any) => {
  console.log(info);
  if (info.file.status === 'done') {
    console.log(`${info.file.name} file uploaded successfully`, info.file);
    return info.file.response.url;
  }
};

export const getDataFile = (info: any) => {
  console.log(info);
  if (info.file.status === 'done') {
    console.log(`${info.file.name} file uploaded successfully`, info.file);
    return info.file.response.data;
  }
};
