import { blue } from '@ant-design/colors';
import { Breadcrumb, PageHeader, SaveButton } from '@refinedev/antd';
import {
  useApiUrl,
  useCustom,
  useCustomMutation,
  useNotification,
} from '@refinedev/core';
import {
  Card,
  Checkbox,
  Divider,
  Form,
  InputNumber,
  Spin,
  Typography,
} from 'antd';
import { valueType } from 'antd/es/statistic/utils';
import { Decimal } from 'decimal.js';
import { useMemo } from 'react';
import { getValueFromConfig } from './helper';

export const LotteryRatioConfig = () => {
  const { open } = useNotification();

  const [form] = Form.useForm<{
    config: {
      id: number;
      matchedCount: number;
      point: number;
      isActive: boolean;
      lotteryWinRatio: {
        id: number;
        code: string;
        value: string;
      };
    }[];
  }>();

  const apiUrl = useApiUrl();
  const { data, isLoading } = useCustom<
    {
      id: number;
      matchedCount: number;
      point: number;
      isActive: boolean;
      lotteryWinRatio: {
        id: number;
        code: string;
        value: string;
      };
    }[]
  >({
    url: `${apiUrl}/lottery/ratio-config`,
    method: 'get',
  });
  const { data: globalConfigData } = useCustom<
    {
      id: string;
      code: string;
      value: string;
    }[]
  >({
    url: `${apiUrl}/lottery/global-config`,
    method: 'get',
  });

  const dailyTicketQuota = useMemo(() => {
    const dailyActiveUser = getValueFromConfig(
      globalConfigData?.data || [],
      'capped_dau',
    );

    const dailyTicketPerUser =
      getValueFromConfig(
        globalConfigData?.data || [],
        'daily_ticket_per_user',
      ) || 24;

    return new Decimal(dailyTicketPerUser).mul(dailyActiveUser);
  }, [globalConfigData?.data]);

  const { mutateAsync, isLoading: isMutating } = useCustomMutation();

  const watchConfig = Form.useWatch('config', form);

  const totalRatio = useMemo(
    () =>
      (watchConfig || []).reduce(
        (total, { lotteryWinRatio: { value }, isActive }) => {
          if (!isActive) return total;

          return total.add(value);
        },
        new Decimal(0),
      ),
    [watchConfig],
  );

  const onFinish = async (data: {
    config: {
      id: number;
      matchedCount: number;
      point: number;
      isActive: boolean;
      lotteryWinRatio: {
        id: number;
        code: string;
        value: string;
      };
    }[];
  }) => {
    await mutateAsync({
      url: `${apiUrl}/lottery/ratio-config`,
      method: 'put',
      values: data,
    });

    if (open) {
      open({
        type: 'success',
        message: 'Successfully edited lottery global config',
        description: 'Successful',
      });
    }
  };

  const canSave = useMemo(() => {
    return totalRatio.equals(1);
  }, [totalRatio]);

  const calculations = useMemo<
    {
      label: string;
      value: valueType;
    }[]
  >(() => {
    if (!watchConfig) {
      return [];
    }

    return [
      ...watchConfig.map(({ lotteryWinRatio: { value }, matchedCount }) => {
        return {
          label: `Quota ${matchedCount}`,
          value: new Decimal(dailyTicketQuota)
            .mul(isNaN(Number(value)) ? 0 : Number(value))
            .toNumber(),
        };
      }),
      {
        label: 'Daily Ticket Quota',
        value: dailyTicketQuota.toNumber(),
      },
    ];
  }, [dailyTicketQuota, watchConfig]);

  return (
    <PageHeader
      title={'Lottery Ratio Config'}
      backIcon={null}
      breadcrumb={<Breadcrumb />}
    >
      <Spin spinning={isLoading}>
        <Card
          bordered={false}
          actions={[
            <SaveButton
              style={{ float: 'right', marginRight: '24px' }}
              onClick={() => {
                form.submit();
              }}
              loading={isMutating}
              disabled={!canSave}
            />,
          ]}
        >
          <Form form={form} onFinish={onFinish}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr max-content',
                alignItems: 'center',
                columnGap: '1rem',
              }}
            >
              <Typography.Title level={3} style={{ marginTop: 0 }}>
                Matched count
              </Typography.Title>
              <Typography.Title level={3} style={{ marginTop: 0 }}>
                Point
              </Typography.Title>
              <Typography.Title level={3} style={{ marginTop: 0 }}>
                Ratio
              </Typography.Title>
              <Typography.Title level={3} style={{ marginTop: 0 }}>
                Is active
              </Typography.Title>

              {(Array.isArray(data?.data) ? data!.data : []).map(
                (config, index) => (
                  <>
                    <Form.Item
                      name={['config', index, 'matchedCount']}
                      initialValue={config.matchedCount}
                    >
                      <InputNumber disabled />
                    </Form.Item>

                    <Form.Item
                      name={['config', index, 'point']}
                      initialValue={config.point}
                    >
                      <InputNumber />
                    </Form.Item>

                    <Form.Item
                      name={['config', index, 'lotteryWinRatio', 'id']}
                      initialValue={config.lotteryWinRatio.id}
                      hidden
                    />

                    <Form.Item
                      name={['config', index, 'lotteryWinRatio', 'value']}
                      initialValue={Number(config.lotteryWinRatio.value)}
                    >
                      <InputNumber />
                    </Form.Item>

                    <Form.Item
                      name={['config', index, 'isActive']}
                      initialValue={config.isActive}
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Form.Item>
                  </>
                ),
              )}

              <Typography.Title
                level={4}
                type={totalRatio.equals(1) ? 'success' : 'danger'}
                style={{
                  gridColumn: '3 / span 1',
                }}
              >
                Total: {new Decimal(100).mul(totalRatio).toNumber()}%
              </Typography.Title>
            </div>
            <Divider />

            <div
              style={{
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: 'max-content 1fr',
                gap: '12px',
              }}
            >
              {calculations.map(({ label, value }) => (
                <>
                  <Typography.Title
                    level={3}
                    style={{
                      margin: 0,
                    }}
                  >
                    {label}:
                  </Typography.Title>
                  <Typography.Title
                    level={3}
                    style={{
                      margin: 0,
                      color: blue[6],
                    }}
                  >
                    {value.toLocaleString()}
                  </Typography.Title>
                </>
              ))}
            </div>
          </Form>
        </Card>
      </Spin>
    </PageHeader>
  );
};
