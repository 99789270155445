import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input, Checkbox, InputNumber } from 'antd';

export const LuckyWheelMissionEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name={['description']}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Max attempt"
          name={['maxAttempt']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} defaultValue={1} />
        </Form.Item>
        <Form.Item
          label="Is Daily mission"
          valuePropName="checked"
          name={['isDaily']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Is Daily</Checkbox>
        </Form.Item>
        {/* <>
                    {(luckyWheelMissionData?.rewards as any[])?.map(
                        (item, index) => (
                            <Form.Item
                                key={index}
                                label={translate(
                                    "lucky-wheel-mission.fields.rewards",
                                )}
                                name={["rewards", index, "name"]}
                            >
                                <Input type="text" />
                            </Form.Item>
                        ),
                    )}
                </> */}
      </Form>
    </Edit>
  );
};
