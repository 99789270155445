import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { CAMPAIGN_REWARD_TYPE_OPTION } from '../../constants';
import { useSelect } from '@refinedev/antd';

export const CampaignRewardEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const campaignRewardData = queryResult?.data?.data;
  const { selectProps: earnCampaignSelectProps } = useSelect({
    resource: 'earn-campaign',
    optionLabel: 'name',
  });

  const campaignRewardTypes = CAMPAIGN_REWARD_TYPE_OPTION;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="ID"
          name={['id']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Reward Type"
          name={['rewardType']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...campaignRewardTypes} />
        </Form.Item>
        <Form.Item
          label="Reward Value"
          name={['rewardValue']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Earn Campaign Name"
          name={['earnCampaignId']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...earnCampaignSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
