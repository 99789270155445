import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, InputNumber, Select } from 'antd';
import { LuckyWheelRewardType } from './constant';

export const LuckyWheelRewardCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name={['type']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={Object.values(LuckyWheelRewardType).map((type) => ({
              label: type,
              value: type,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Amount"
          name={['amount']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} defaultValue={0} />
        </Form.Item>
        <Form.Item
          label="Threshold"
          name={['threshold']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} defaultValue={0} step="0.01" />
        </Form.Item>
      </Form>
    </Create>
  );
};
