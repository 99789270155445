import { AuthProvider } from '@pankod/refine-core';
import axios, { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';

export const ACCESS_TOKEN_KEY = 'refine-access-token';
export const USER_KEY = 'refine-user';
export const PROVIDER_URL =
  process.env.REACT_APP_PROVIDER_URL || 'http://localhost:3004';

export interface IGaAuth {
  checkGa: (params?: any) => Promise<any>;
}

export const authProvider: AuthProvider & IGaAuth = {
  login: async ({ email, token, otp }) => {
    const { status, data } = await axios.post(`${PROVIDER_URL}/auth/login`, {
      email,
      token,
      otp,
    });

    if (status === 200) {
      const { accessToken } = data;
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      localStorage.setItem(USER_KEY, JSON.stringify(jwt_decode(accessToken)));
      return Promise.resolve({
        accessToken,
      });
    }

    return Promise.reject(new Error('Login failed'));
  },
  logout: () => Promise.resolve(),
  checkError: (error) => Promise.resolve(error),
  checkAuth: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
  checkGa: async ({ email }) => {
    const { status, data } = await axios.post(`${PROVIDER_URL}/auth/ga`, {
      email,
    });

    if (status === 200) {
      const { otpauthUrl, base32 } = data;
      return Promise.resolve({
        otpauthUrl,
        base32,
      });
    }

    return Promise.reject(new Error('User exists'));
  },
};

export { axios };
export type { AxiosRequestConfig };
