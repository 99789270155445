import { Col, Collapse, Descriptions, Row, Table, Typography } from 'antd';
import {
  UserMigrateDetail,
  UserMigrateDetailUserDetail,
} from 'interfaces/user-migrate';
import { pull } from 'lodash';

const { Title } = Typography;

const SECTIONS: {
  name: string;
  property: keyof UserMigrateDetailUserDetail;
}[] = [
  {
    name: 'User info',
    property: 'userInfo',
  },
  {
    name: 'Identity verification',
    property: 'identityVerification',
  },
  {
    name: 'User referral info',
    property: 'userReferral',
  },
  {
    name: 'Ota balance info',
    property: 'otaBalance',
  },
  {
    name: 'Ota level info',
    property: 'otaLevel',
  },
  {
    name: 'Ota mining history',
    property: 'otaMiningHistory',
  },
];

const camelCaseToText = (camelCase: string) => {
  const text = camelCase;
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const DrawerMigrateInfo: React.FC<{
  userMigrateDetail: UserMigrateDetail;
}> = ({ userMigrateDetail }) => {
  const renderData = (obj: any, title: string) => {
    if (Array.isArray(obj)) return renderArrayData(obj, title);

    return (
      <Descriptions bordered title={title} column={1}>
        {!!obj &&
          Object.keys(obj).map((key) => (
            <Descriptions.Item key={key} label={camelCaseToText(key)}>
              {obj[key]?.toString()}
            </Descriptions.Item>
          ))}
      </Descriptions>
    );
  };

  const renderArrayData = (objArray: any[], title: string) => {
    if (objArray.length === 0) return null;

    return (
      <>
        <Title level={5} style={{ margin: '0 0 20px' }}>
          {title}
        </Title>
        <Table
          dataSource={objArray.map((obj, index) => ({
            id: index,
            ...obj,
          }))}
          rowKey="id"
          pagination={false}
        >
          {pull(Object.keys(objArray[0]), 'updatedAt').map((key) => {
            return (
              <Table.Column
                dataIndex={key}
                title={camelCaseToText(key)}
                key={key}
              />
            );
          })}
        </Table>
      </>
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Collapse defaultActiveKey={SECTIONS.map(({ property }) => property)}>
          {SECTIONS.map(({ name, property }) => (
            <Collapse.Panel header={name} key={property}>
              <Row>
                <Col span={24}>
                  <Collapse
                    defaultActiveKey={[
                      `${property}-old-user`,
                      `${property}-new-user`,
                    ]}
                  >
                    <Collapse.Panel
                      header="Old user"
                      key={`${property}-old-user`}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24} xxl={12}>
                          {renderData(
                            userMigrateDetail?.result.before.oldUser[property],
                            'Before',
                          )}
                        </Col>
                        <Col span={24} xxl={12}>
                          {renderData(
                            userMigrateDetail?.result.after.oldUser[property],
                            'After',
                          )}
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel
                      header="New user"
                      key={`${property}-new-user`}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24} xxl={12}>
                          {renderData(
                            userMigrateDetail?.result.before.newUser[property],
                            'Before',
                          )}
                        </Col>
                        <Col span={24} xxl={12}>
                          {renderData(
                            userMigrateDetail?.result.after.newUser[property],
                            'After',
                          )}
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
            </Collapse.Panel>
          ))}
        </Collapse>
      </Col>
    </Row>
  );
};
