import { blue, grey } from '@ant-design/colors';
import { Breadcrumb, PageHeader } from '@refinedev/antd';
import { useApiUrl, useCustom } from '@refinedev/core';
import { Divider, Radio, Spin, Typography } from 'antd';
import Decimal from 'decimal.js';
import { useEffect, useMemo, useState } from 'react';
import { getValueFromConfig } from './helper';

const INTERVAL_TIME_OPTIONS = [
  {
    label: '1s',
    value: 1_000,
  },
  {
    label: '5s',
    value: 5_000,
  },
  {
    label: '15s',
    value: 15_000,
  },
  {
    label: '30s',
    value: 30_000,
  },
  {
    label: '60s',
    value: 60_000,
  },
  {
    label: '5m',
    value: 5 * 60_000,
  },
  {
    label: '15m',
    value: 15 * 60_000,
  },
  {
    label: '30m',
    value: 30 * 60_000,
  },
  {
    label: '1h',
    value: 60 * 60_000,
  },
];

export const LotteryRealtimeDashboard = () => {
  const [intervalTime, setIntervalTime] = useState(60 * 60_000);
  const apiUrl = useApiUrl();
  const {
    data: ratioConfigData,
    isLoading: isLoadingRatioConfig,
    refetch: refetchRatioConfig,
  } = useCustom<
    {
      id: number;
      matchedCount: number;
      point: number;
      isActive: boolean;
      lotteryWinRatio: {
        id: number;
        code: string;
        value: string;
      };
    }[]
  >({
    url: `${apiUrl}/lottery/ratio-config`,
    method: 'get',
  });

  const {
    data: globalConfigData,
    isLoading: isLoadingGlobalConfig,
    refetch: refetchGlobalConfig,
  } = useCustom<
    {
      id: string;
      code: string;
      value: string;
    }[]
  >({
    url: `${apiUrl}/lottery/global-config`,
    method: 'get',
  });

  const {
    data: realtimeDashboardData,
    isLoading: isLoadingRealtimeDashboard,
    refetch: refetchRealtimeDashboard,
  } = useCustom({
    url: `${apiUrl}/lottery/realtime-dashboard`,
    method: 'get',
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetchRatioConfig();
      refetchGlobalConfig();
      refetchRealtimeDashboard();
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [intervalTime]);

  const isLoading =
    isLoadingRatioConfig || isLoadingGlobalConfig || isLoadingRealtimeDashboard;

  const dailyTicketQuota = useMemo(() => {
    const dailyActiveUser = getValueFromConfig(
      globalConfigData?.data || [],
      'capped_dau',
    );

    const dailyTicketPerUser =
      getValueFromConfig(
        globalConfigData?.data || [],
        'daily_ticket_per_user',
      ) || 24;

    return new Decimal(dailyTicketPerUser).mul(dailyActiveUser);
  }, [globalConfigData?.data]);

  const entries: {
    label: string;
    value: string;
    disabled: boolean;
    divider?: boolean;
  }[] = useMemo(() => {
    const ratios = ratioConfigData?.data ?? [];

    return [
      {
        label: 'Number of users joined scratched card',
        value: (
          realtimeDashboardData?.data.ticketPurchasedCount ?? 0
        ).toLocaleString(),
        disabled: false,
      },
      {
        label: 'Number of users purchased Jackpot',
        value: (
          realtimeDashboardData?.data.scratchedCardUserCount ?? 0
        ).toLocaleString(),
        disabled: false,
        divider: true,
      },
      {
        label: 'Daily Card Quota',
        value: `${(
          realtimeDashboardData?.data.total ?? 0
        ).toLocaleString()} / ${dailyTicketQuota.toNumber().toLocaleString()}`,
        disabled: false,
      },
      ...ratios.map(
        ({ matchedCount, lotteryWinRatio: { value }, isActive }, index) => ({
          label: `Quota ${matchedCount}`,
          value: `${(
            realtimeDashboardData?.data[`matched${matchedCount}Count`] ?? 0
          ).toLocaleString()} / ${new Decimal(dailyTicketQuota)
            .mul(isNaN(Number(value)) ? 0 : Number(value))
            .toNumber()
            .toLocaleString()}`,
          disabled: !isActive,
          divider: index === ratios.length - 1,
        }),
      ),
      {
        label: 'All Time Card Quota',
        value: (realtimeDashboardData?.data.totalAllTime ?? 0).toLocaleString(),
        disabled: false,
      },
      ...ratios.map(({ matchedCount, isActive }) => ({
        label: `Quota ${matchedCount} All Time`,
        value: (
          realtimeDashboardData?.data[`matched${matchedCount}CountAllTime`] ?? 0
        ).toLocaleString(),
        disabled: !isActive,
      })),
    ];
  }, [ratioConfigData, dailyTicketQuota, realtimeDashboardData]);

  return (
    <PageHeader
      title={'Lottery Realtime Dashboard'}
      backIcon={null}
      breadcrumb={<Breadcrumb />}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '48px',
        }}
      >
        <Typography.Title level={5} style={{ margin: 0 }}>
          Refresh time
        </Typography.Title>
        <Radio.Group
          buttonStyle="solid"
          value={intervalTime}
          onChange={({ target: { value } }) => {
            setIntervalTime(value);
          }}
        >
          {INTERVAL_TIME_OPTIONS.map(({ label, value }) => (
            <Radio.Button value={value}>{label}</Radio.Button>
          ))}
        </Radio.Group>
      </div>
      <Spin spinning={isLoading}>
        <div
          style={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'max-content 1fr',
            gap: '12px',
          }}
        >
          {entries.map(({ label, value, disabled, divider }) => (
            <>
              <Typography.Title
                level={3}
                style={{
                  margin: 0,
                  color: disabled ? grey.primary : undefined,
                }}
              >
                {label}:
              </Typography.Title>
              <Typography.Title
                level={3}
                style={{
                  margin: 0,
                  color: disabled ? grey.primary : blue[6],
                }}
              >
                {value}
              </Typography.Title>
              {!!divider && <Divider style={{ gridColumn: '1 / span 2' }} />}
            </>
          ))}
        </div>
      </Spin>
    </PageHeader>
  );
};
