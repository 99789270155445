import React from 'react';
import { IResourceComponentsProps, BaseRecord, useCan } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  TagField,
  EmailField,
  DeleteButton,
} from '@refinedev/antd';
import { Table, Space } from 'antd';
import { capitalize } from 'lodash';
import { RoleTagColor } from './constant/partnerRole';

export const PartnerList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: canData } = useCan({
    action: 'delete',
    resource: 'partners',
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex={['email']}
          title="Email"
          render={(value: string) => <EmailField value={value} />}
        />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="role"
          title="Role"
          render={(value: string) => (
            <TagField value={capitalize(value)} color={RoleTagColor[value]} />
          )}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              {!!canData?.can && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
