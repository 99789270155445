import React, { useState } from 'react';
import { IResourceComponentsProps, useApiUrl } from '@refinedev/core';
import { Edit, ImageField, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Checkbox, InputNumber, Select, Upload } from 'antd';
import { getFile } from '../../utils/upload-file';

export const GameEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();
  const [newLogo, setNewLogo] = useState<string | null>('');

  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');

  const { selectProps: gameSelectProps } = useSelect({
    resource: 'game-category',
    optionLabel: 'name',
    optionValue: 'id',
  });

  const record = queryResult?.data?.data;
  const logo = newLogo || record?.logo;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Slug"
          name={['slug']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Name"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name={['description']}>
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          label="Source"
          name={['source']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Logo">
          <ImageField value={logo} height={200} />
          <Form.Item name="Logo" getValueFromEvent={getFile}>
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/medias/image`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              maxCount={1}
              onChange={({ file }) => {
                setNewLogo(file.response?.url);
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Sort" name={['sort']}>
          <InputNumber min={0} defaultValue={0} />
        </Form.Item>
        <Form.Item
          label="Category"
          name={['categoryId']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...gameSelectProps} />
        </Form.Item>
        <Form.Item
          label="Is active"
          valuePropName="checked"
          name={['isActive']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
      </Form>
    </Edit>
  );
};
