import React from 'react';
import { IResourceComponentsProps, useShow, useOne } from '@refinedev/core';
import { Show, NumberField, TextField, DateField } from '@refinedev/antd';
import { Typography } from 'antd';

const { Title } = Typography;

export const EarnClaimHistoryShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: userData, isLoading: userIsLoading } = useOne({
    resource: 'users',
    id: record?.userId || '',
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show
      isLoading={isLoading}
      canEdit={record?.status === 'new'}
      canDelete={false}
    >
      <Title level={5}>ID</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>User</Title>
      {userIsLoading ? <>Loading...</> : <>{userData?.data?.name}</>}
      <Title level={5}>Amount</Title>
      <NumberField value={record?.amount ?? ''} />
      <Title level={5}>BUSD Amount</Title>
      <NumberField value={record?.busdAmount ?? ''} />
      <Title level={5}>Address</Title>
      <TextField value={record?.address ?? ''} />
      <Title level={5}>Admin Note</Title>
      <TextField value={record?.adminNote} />
      <Title level={5}>Payment Note</Title>
      <TextField value={record?.paymentNote} />
      <Title level={5}>Status</Title>
      <TextField value={record?.status} />
      <Title level={5}>Created At</Title>
      <DateField value={record?.createdAt} />
      <Title level={5}>Updated At</Title>
      <DateField value={record?.updatedAt} />
    </Show>
  );
};
