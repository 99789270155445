import { AuthBindings, Authenticated, Refine } from '@refinedev/core';
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar';

import {
  ErrorComponent,
  ThemedLayoutV2,
  notificationProvider,
  ThemedTitleV2,
} from '@refinedev/antd';
import '@refinedev/antd/dist/reset.css';

import nestjsxCrudDataProvider from '@refinedev/nestjsx-crud';
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import { UsersCreate, UsersEdit, UsersList, UsersShow } from 'pages/users';
import {
  KycLv2Create,
  KycLv2Edit,
  KycLv2List,
  KycLv2Show,
} from 'pages/kyc-lv2s';
import { DocumentAssessmentKycList } from 'pages/document-assessment-kycs';
import {
  HypervergeCreate,
  HypervergeEdit,
  HypervergeList,
  HypervergeShow,
} from 'pages/hyperverges';
import { CloudwatchList } from 'pages/cloudwatches';
import { Login } from 'pages/login';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { Header } from './components/header';
import { IconImota } from './components/logo';
import { ColorModeContextProvider } from './contexts/color-mode';
import { ACCESS_TOKEN_KEY, PROVIDER_URL, USER_KEY } from './authProvider';
import { ac } from './ac';
import { axiosInstance } from 'axiosConfig';
import { UserMigrateCreate, UserMigrateList } from 'pages/user-migrates';
import {
  EarnClaimHistoryEdit,
  EarnClaimHistoryList,
  EarnClaimHistoryShow,
} from './pages/earn-claim-histories';
import {
  EarnNetworkCreate,
  EarnNetworkEdit,
  EarnNetworkList,
  EarnNetworkShow,
} from './pages/earn-networks';
import {
  EarnCampaignCreate,
  EarnCampaignEdit,
  EarnCampaignList,
  EarnCampaignShow,
} from './pages/earn-campaigns';
import {
  CampaignRewardCreate,
  CampaignRewardEdit,
  CampaignRewardList,
  CampaignRewardShow,
} from './pages/campaign-rewards';
import {
  EarnWidgetCreate,
  EarnWidgetEdit,
  EarnWidgetList,
  EarnWidgetShow,
} from './pages/earn-widgets';
import {
  EarnCategoryCreate,
  EarnCategoryEdit,
  EarnCategoryList,
  EarnCategoryShow,
} from './pages/earn-categories';
import {
  EarnUserHistoryList,
  EarnUserHistoryShow,
} from './pages/earn-user-histories';
import { WalletConfigList } from './pages/wallet-configs';
import { GameCreate, GameEdit, GameList, GameShow } from './pages/games';
import {
  GameCategoryCreate,
  GameCategoryEdit,
  GameCategoryList,
  GameCategoryShow,
} from './pages/game-categories';
import {
  PartnerCreate,
  PartnerEdit,
  PartnerList,
  PartnerShow,
} from './pages/partners';
import {
  LuckyWheelRewardCreate,
  LuckyWheelRewardEdit,
  LuckyWheelRewardList,
  LuckyWheelRewardShow,
} from './pages/lucky-wheel-rewards';
import {
  LuckyWheelMissionEdit,
  LuckyWheelMissionList,
  LuckyWheelMissionShow,
} from './pages/lucky-wheel-missions';
import {
  UserDataMigrationCreate,
  UserDataMigrationList,
  UserDataMigrationShow,
} from './pages/user-data-migrations';
import {
  OtaRewardBatchCreate,
  OtaRewardBatchEdit,
  OtaRewardBatchList,
  OtaRewardBatchShow,
} from './pages/ota-reward-batches';
import {
  PushNotificationCreate,
  PushNotificationEdit,
  PushNotificationList,
  PushNotificationShow,
} from './pages/push-notifications';

import { Top10EnergyStoneList } from './pages/top-10-energy-stones';
import { LotteryGlobalConfig } from './pages/lotteries';
import { LotteryRatioConfig } from './pages/lotteries/ratio';
import { LotteryRealtimeDashboard } from './pages/lotteries/realtime';
import { LotteryResult } from './pages/lotteries/result';
import {
  FootballFestMatchList,
  FootballFestMatchShow,
} from './pages/football-fest-matches';
import {
  FootballFestCategoryList,
  FootballFestCategoryShow,
} from './pages/football-fest-category';

function App() {
  const { t, i18n } = useTranslation();

  const API_URL = PROVIDER_URL;
  const dataProvider = nestjsxCrudDataProvider(API_URL, axiosInstance);

  const authProvider: AuthBindings = {
    login: () =>
      Promise.resolve({
        success: true,
      }),
    logout: async () => {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);

      if (token && typeof window !== 'undefined') {
        localStorage.removeItem(USER_KEY);
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        axiosInstance.defaults.headers.common = {};
      }

      return {
        success: true,
        redirectTo: '/login',
      };
    },
    onError: async (error) => {
      const status = error.statusCode;
      if (status === 401) {
        return {
          authenticated: false,
          error: new Error('Not authenticated'),
          logout: true,
          redirectTo: '/login',
        };
      }
      return { error };
    },
    check: async () => {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (token) {
        return {
          authenticated: true,
        };
      }
      return {
        authenticated: false,
        error: new Error('Not authenticated'),
        logout: true,
        redirectTo: '/login',
      };
    },
    getPermissions: async () => null,
    getIdentity: async () => {
      try {
        const user = localStorage.getItem(USER_KEY);

        if (user) {
          return JSON.parse(user);
        }
      } catch (error) {
        console.log(`Unable to get identity in localstorage`);
      }
    },
  };

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      {/* <GitHubBanner /> */}
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={dataProvider}
            notificationProvider={notificationProvider}
            routerProvider={{
              ...routerBindings,
              routes: [
                {
                  element: LotteryGlobalConfig,
                  path: '/lottery-global-config',
                  layout: true,
                },
                {
                  element: LotteryRatioConfig,
                  path: '/lottery-ratio-config',
                },
                {
                  element: LotteryRealtimeDashboard,
                  path: '/lottery-realtime-dashboard',
                },
                {
                  element: LotteryResult,
                  path: '/lottery-result',
                },
              ],
            }}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            accessControlProvider={{
              can: async ({ resource, action }) => {
                let can = false;
                const stringifyUser = localStorage.getItem(USER_KEY);
                if (stringifyUser) {
                  const { role } = JSON.parse(stringifyUser);

                  switch (action) {
                    case 'list':
                    case 'show':
                      can = ac
                        .can(role)
                        .read(resource?.replace('/', '-')).granted;
                      break;
                    case 'create':
                      can = ac
                        .can(role)
                        .create(resource?.replace('/', '-')).granted;
                      break;
                    case 'edit':
                      can = ac
                        .can(role)
                        .update(resource?.replace('/', '-')).granted;
                      break;
                    case 'delete':
                      can = ac
                        .can(role)
                        .delete(resource?.replace('/', '-')).granted;
                      break;
                  }
                }
                return Promise.resolve({ can });
              },
            }}
            resources={[
              {
                name: 'user-management',
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<UsersList/>` component at `/users` path.
                 * Use `<UsersCreate/>` component at `/users/create` path.
                 * Use `<UsersEdit/>` component at `/users/edit/:id` path.
                 * Use `<UsersShow/>` component at `/users/show/:id` path.
                 *
                 **/
                name: 'users',

                list: '/users',
                create: '/users/create',
                edit: '/users/edit/:id',
                show: '/users/show/:id',
                meta: {
                  canDelete: true,
                  parent: 'user-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Kyc-lv2List/>` component at `/kyc-lv2` path.
                 * Use `<Kyc-lv2Create/>` component at `/kyc-lv2/create` path.
                 * Use `<Kyc-lv2Edit/>` component at `/kyc-lv2/edit/:id` path.
                 * Use `<Kyc-lv2Show/>` component at `/kyc-lv2/show/:id` path.
                 *
                 **/
                name: 'kyc-lv2',

                list: '/kyc-lv2',
                create: '/kyc-lv2/create',
                edit: '/kyc-lv2/edit/:id',
                show: '/kyc-lv2/show/:id',
                meta: {
                  parent: 'user-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Document-assessment-kycList/>` component at `/document-assessment-kyc` path.
                 * Use `<Document-assessment-kycShow/>` component at `/document-assessment-kyc/show/:id` path.
                 *
                 **/
                name: 'document-assessment-kyc',

                list: '/document-assessment-kyc',
                show: '/document-assessment-kyc/show/:id',
                meta: {
                  parent: 'user-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<HypervergeList/>` component at `/hyperverge` path.
                 * Use `<HypervergeCreate/>` component at `/hyperverge/create` path.
                 * Use `<HypervergeEdit/>` component at `/hyperverge/edit/:id` path.
                 * Use `<HypervergeShow/>` component at `/hyperverge/show/:id` path.
                 *
                 **/
                name: 'hyperverge',

                list: '/hyperverge',
                create: '/hyperverge/create',
                edit: '/hyperverge/edit/:id',
                show: '/hyperverge/show/:id',
                meta: {
                  parent: 'user-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<User-migrateList/>` component at `/user-migrate` path.
                 * Use `<User-migrateCreate/>` component at `/user-migrate/create` path.
                 * Use `<User-migrateEdit/>` component at `/user-migrate/edit/:id` path.
                 * Use `<User-migrateShow/>` component at `/user-migrate/show/:id` path.
                 *
                 **/
                name: 'user-migrate',

                list: '/user-migrate',
                create: '/user-migrate/create',
                meta: {
                  parent: 'user-management',
                },
              },
              {
                name: 'earn-management',
              },
              {
                name: 'earn',
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Earn-claim-historyList/>` component at `/earn-claim-history` path.
                 * Use `<Earn-claim-historyCreate/>` component at `/earn-claim-history/create` path.
                 * Use `<Earn-claim-historyEdit/>` component at `/earn-claim-history/edit/:id` path.
                 * Use `<Earn-claim-historyShow/>` component at `/earn-claim-history/show/:id` path.
                 *
                 **/
                name: 'earn-claim-history',

                list: '/earn-claim-history',
                edit: '/earn-claim-history/edit/:id',
                show: '/earn-claim-history/show/:id',
                meta: {
                  parent: 'earn-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Earn-networkList/>` component at `/earn-network` path.
                 * Use `<Earn-networkCreate/>` component at `/earn-network/create` path.
                 * Use `<Earn-networkEdit/>` component at `/earn-network/edit/:id` path.
                 * Use `<Earn-networkShow/>` component at `/earn-network/show/:id` path.
                 *
                 **/
                name: 'earn-network',

                list: '/earn-network',
                create: '/earn-network/create',
                edit: '/earn-network/edit/:id',
                show: '/earn-network/show/:id',
                meta: {
                  parent: 'earn-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Earn-campaignList/>` component at `/earn-campaign` path.
                 * Use `<Earn-campaignCreate/>` component at `/earn-campaign/create` path.
                 * Use `<Earn-campaignEdit/>` component at `/earn-campaign/edit/:id` path.
                 * Use `<Earn-campaignShow/>` component at `/earn-campaign/show/:id` path.
                 *
                 **/
                name: 'earn-campaign',

                list: '/earn-campaign',
                create: '/earn-campaign/create',
                edit: '/earn-campaign/edit/:id',
                show: '/earn-campaign/show/:id',
                meta: {
                  parent: 'earn-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Campaign-rewardList/>` component at `/campaign-reward` path.
                 * Use `<Campaign-rewardCreate/>` component at `/campaign-reward/create` path.
                 * Use `<Campaign-rewardEdit/>` component at `/campaign-reward/edit/:id` path.
                 * Use `<Campaign-rewardShow/>` component at `/campaign-reward/show/:id` path.
                 *
                 **/
                name: 'campaign-reward',

                list: '/campaign-reward',
                create: '/campaign-reward/create',
                edit: '/campaign-reward/edit/:id',
                show: '/campaign-reward/show/:id',
                meta: {
                  parent: 'earn-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Earn-widgetList/>` component at `/earn-widget` path.
                 * Use `<Earn-widgetCreate/>` component at `/earn-widget/create` path.
                 * Use `<Earn-widgetEdit/>` component at `/earn-widget/edit/:id` path.
                 * Use `<Earn-widgetShow/>` component at `/earn-widget/show/:id` path.
                 *
                 **/
                name: 'earn-widget',

                list: '/earn-widget',
                create: '/earn-widget/create',
                edit: '/earn-widget/edit/:id',
                show: '/earn-widget/show/:id',
                meta: {
                  parent: 'earn-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Earn-categoryList/>` component at `/earn-category` path.
                 * Use `<Earn-categoryCreate/>` component at `/earn-category/create` path.
                 * Use `<Earn-categoryEdit/>` component at `/earn-category/edit/:id` path.
                 * Use `<Earn-categoryShow/>` component at `/earn-category/show/:id` path.
                 *
                 **/
                name: 'earn-category',

                list: '/earn-category',
                create: '/earn-category/create',
                edit: '/earn-category/edit/:id',
                show: '/earn-category/show/:id',
                meta: {
                  parent: 'earn-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Earn-user-historyList/>` component at `/earn-user-history` path.
                 * Use `<Earn-user-historyCreate/>` component at `/earn-user-history/create` path.
                 * Use `<Earn-user-historyEdit/>` component at `/earn-user-history/edit/:id` path.
                 * Use `<Earn-user-historyShow/>` component at `/earn-user-history/show/:id` path.
                 *
                 **/
                name: 'earn-user-history',

                list: '/earn-user-history',
                show: '/earn-user-history/show/:id',
                meta: {
                  parent: 'earn-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Wallet-configList/>` component at `/wallet-config` path.
                 * Use `<Wallet-configCreate/>` component at `/wallet-config/create` path.
                 * Use `<Wallet-configEdit/>` component at `/wallet-config/edit/:id` path.
                 * Use `<Wallet-configShow/>` component at `/wallet-config/show/:id` path.
                 *
                 **/
                name: 'wallet-configs',
                list: '/wallet-configs',
                meta: {
                  parent: 'user-management',
                },
              },
              {
                name: 'game-management',
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<GameList/>` component at `/game` path.
                 * Use `<GameCreate/>` component at `/game/create` path.
                 * Use `<GameEdit/>` component at `/game/edit/:id` path.
                 * Use `<GameShow/>` component at `/game/show/:id` path.
                 *
                 **/
                name: 'game',

                list: '/game',
                create: '/game/create',
                edit: '/game/edit/:id',
                show: '/game/show/:id',
                meta: {
                  parent: 'game-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Game-categoryList/>` component at `/game-category` path.
                 * Use `<Game-categoryCreate/>` component at `/game-category/create` path.
                 * Use `<Game-categoryEdit/>` component at `/game-category/edit/:id` path.
                 * Use `<Game-categoryShow/>` component at `/game-category/show/:id` path.
                 *
                 **/
                name: 'game-category',

                list: '/game-category',
                create: '/game-category/create',
                edit: '/game-category/edit/:id',
                show: '/game-category/show/:id',
                meta: {
                  parent: 'game-management',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<User-data-migrationList/>` component at `/user-data-migration` path.
                 * Use `<User-data-migrationCreate/>` component at `/user-data-migration/create` path.
                 * Use `<User-data-migrationEdit/>` component at `/user-data-migration/edit/:id` path.
                 * Use `<User-data-migrationShow/>` component at `/user-data-migration/show/:id` path.
                 *
                 **/
                name: 'user-data-migration',

                list: '/user-data-migration',
                create: '/user-data-migration/create',
                show: '/user-data-migration/show/:id',
                meta: {
                  parent: 'tool',
                  label: 'Revert Otara Mining V2',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Ota-reward-batchList/>` component at `/ota-reward-batch` path.
                 * Use `<Ota-reward-batchCreate/>` component at `/ota-reward-batch/create` path.
                 * Use `<Ota-reward-batchEdit/>` component at `/ota-reward-batch/edit/:id` path.
                 * Use `<Ota-reward-batchShow/>` component at `/ota-reward-batch/show/:id` path.
                 *
                 **/
                name: 'ota-reward-batch',

                list: '/ota-reward-batch',
                create: '/ota-reward-batch/create',
                edit: '/ota-reward-batch/edit/:id',
                show: '/ota-reward-batch/show/:id',
                meta: {
                  parent: 'tool',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<PartnerList/>` component at `/partner` path.
                 * Use `<PartnerCreate/>` component at `/partner/create` path.
                 * Use `<PartnerEdit/>` component at `/partner/edit/:id` path.
                 * Use `<PartnerShow/>` component at `/partner/show/:id` path.
                 *
                 **/
                name: 'partner',

                list: '/partner',
                create: '/partner/create',
                edit: '/partner/edit/:id',
                show: '/partner/show/:id',
                meta: {
                  canDelete: true,
                },
              },
              {
                name: 'lucky-wheel',
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Lucky-wheel-rewardList/>` component at `/lucky-wheel-reward` path.
                 * Use `<Lucky-wheel-rewardCreate/>` component at `/lucky-wheel-reward/create` path.
                 * Use `<Lucky-wheel-rewardEdit/>` component at `/lucky-wheel-reward/edit/:id` path.
                 * Use `<Lucky-wheel-rewardShow/>` component at `/lucky-wheel-reward/show/:id` path.
                 *
                 **/
                name: 'lucky-wheel-reward',

                list: '/lucky-wheel-reward',
                create: '/lucky-wheel-reward/create',
                edit: '/lucky-wheel-reward/edit/:id',
                show: '/lucky-wheel-reward/show/:id',
                meta: {
                  canDelete: true,
                  parent: 'lucky-wheel',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Lucky-wheel-missionList/>` component at `/lucky-wheel-mission` path.
                 * Use `<Lucky-wheel-missionCreate/>` component at `/lucky-wheel-mission/create` path.
                 * Use `<Lucky-wheel-missionEdit/>` component at `/lucky-wheel-mission/edit/:id` path.
                 * Use `<Lucky-wheel-missionShow/>` component at `/lucky-wheel-mission/show/:id` path.
                 *
                 **/
                name: 'lucky-wheel-mission',

                list: '/lucky-wheel-mission',
                edit: '/lucky-wheel-mission/edit/:id',
                show: '/lucky-wheel-mission/show/:id',
                meta: {
                  parent: 'lucky-wheel',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<CloudwatchList/>` component at `/cloudwatch` path.
                 * Use `<CloudwatchCreate/>` component at `/cloudwatch/create` path.
                 * Use `<CloudwatchEdit/>` component at `/cloudwatch/edit/:id` path.
                 * Use `<CloudwatchShow/>` component at `/cloudwatch/show/:id` path.
                 *
                 **/
                name: 'cloudwatch',

                list: '/cloudwatch',
                meta: {
                  parent: 'tool',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Top-10-energy-stoneList/>` component at `/top-10-energy-stone` path.
                 *
                 **/
                name: 'top-10-energy-stone',

                list: '/top-10-energy-stone',
                meta: {
                  parent: 'lucky-wheel',
                },
              },
              {
                name: 'tool',
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<Push-notificationList/>` component at `/push-notification` path.
                 * Use `<Push-notificationCreate/>` component at `/push-notification/create` path.
                 * Use `<Push-notificationEdit/>` component at `/push-notification/edit/:id` path.
                 * Use `<Push-notificationShow/>` component at `/push-notification/show/:id` path.
                 *
                 **/
                name: 'push-notification',

                list: '/push-notification',
                create: '/push-notification/create',
                edit: '/push-notification/edit/:id',
                show: '/push-notification/show/:id',
                meta: {
                  parent: 'tool',
                },
              },
              {
                /**
                 *
                 * Resource is default with default paths, you need to add the components to the paths accordingly.
                 * You can also add custom paths to the resource.
                 *
                 * Use `<LotteryList/>` component at `/lottery` path.
                 * Use `<LotteryCreate/>` component at `/lottery/create` path.
                 * Use `<LotteryEdit/>` component at `/lottery/edit/:id` path.
                 * Use `<LotteryShow/>` component at `/lottery/show/:id` path.
                 *
                 **/
                name: 'lottery',

                list: () => null,
              },
              {
                name: 'lottery-global-config',

                list: () => null,
                meta: {
                  parent: 'lottery',
                },
              },
              {
                name: 'lottery-ratio-config',

                list: () => null,
                meta: {
                  parent: 'lottery',
                },
              },
              {
                name: 'lottery-realtime-dashboard',

                list: () => null,
                meta: {
                  parent: 'lottery',
                },
              },
              {
                name: 'lottery-result',

                list: () => null,
                meta: {
                  parent: 'lottery',
                },
              },
              {
                name: 'football-fest',

                list: () => null,
              },
              {
                name: 'football-fest-category',
                list: '/football-fest/category',
                edit: '/football-fest/category/edit/:id',
                show: '/football-fest/category/show/:id',
                meta: {
                  parent: 'football-fest',
                },
              },
              {
                name: 'football-fest-match',
                list: '/football-fest/match',
                edit: '/football-fest/match/edit/:id',
                show: '/football-fest/match/show/:id',
                meta: {
                  parent: 'football-fest',
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated
                    fallback={<CatchAllNavigate to="/login" />}
                    key="dashboard"
                  >
                    <ThemedLayoutV2
                      Header={Header}
                      Title={({ collapsed }) => (
                        <ThemedTitleV2
                          // collapsed is a boolean value that indicates whether the <Sidebar> is collapsed or not
                          collapsed={collapsed}
                          icon={
                            collapsed ? <IconImota width={30} /> : <IconImota />
                          }
                          text=""
                        />
                      )}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route
                  index
                  element={<NavigateToResource resource="users" />}
                />
                <Route path="/users">
                  <Route index element={<UsersList />} />
                  <Route path="create" element={<UsersCreate />} />
                  <Route path="edit/:id" element={<UsersEdit />} />
                  <Route path="show/:id" element={<UsersShow />} />
                </Route>
                <Route path="/kyc-lv2">
                  <Route index element={<KycLv2List />} />
                  <Route path="create" element={<KycLv2Create />} />
                  <Route path="edit/:id" element={<KycLv2Edit />} />
                  <Route path="show/:id" element={<KycLv2Show />} />
                </Route>
                <Route path="/document-assessment-kyc">
                  <Route index element={<DocumentAssessmentKycList />} />
                </Route>
                <Route path="/hyperverge">
                  <Route index element={<HypervergeList />} />
                  <Route path="create" element={<HypervergeCreate />} />
                  <Route path="edit/:id" element={<HypervergeEdit />} />
                  <Route path="show/:id" element={<HypervergeShow />} />
                </Route>
                <Route path="/user-migrate">
                  <Route index element={<UserMigrateList />} />
                  <Route path="create" element={<UserMigrateCreate />} />
                </Route>
                <Route path="/cloudwatch">
                  <Route index element={<CloudwatchList />} />
                </Route>

                <Route path="/earn-claim-history">
                  <Route index element={<EarnClaimHistoryList />} />
                  <Route path="edit/:id" element={<EarnClaimHistoryEdit />} />
                  <Route path="show/:id" element={<EarnClaimHistoryShow />} />
                </Route>

                <Route path="/earn-network">
                  <Route index element={<EarnNetworkList />} />
                  <Route path="create" element={<EarnNetworkCreate />} />
                  <Route path="edit/:id" element={<EarnNetworkEdit />} />
                  <Route path="show/:id" element={<EarnNetworkShow />} />
                </Route>

                <Route path="/earn-campaign">
                  <Route index element={<EarnCampaignList />} />
                  <Route path="create" element={<EarnCampaignCreate />} />
                  <Route path="edit/:id" element={<EarnCampaignEdit />} />
                  <Route path="show/:id" element={<EarnCampaignShow />} />
                </Route>

                <Route path="/campaign-reward">
                  <Route index element={<CampaignRewardList />} />
                  <Route path="create" element={<CampaignRewardCreate />} />
                  <Route path="edit/:id" element={<CampaignRewardEdit />} />
                  <Route path="show/:id" element={<CampaignRewardShow />} />
                </Route>

                <Route path="/earn-widget">
                  <Route index element={<EarnWidgetList />} />
                  <Route path="create" element={<EarnWidgetCreate />} />
                  <Route path="edit/:id" element={<EarnWidgetEdit />} />
                  <Route path="show/:id" element={<EarnWidgetShow />} />
                </Route>
                <Route path="/earn-category">
                  <Route index element={<EarnCategoryList />} />
                  <Route path="create" element={<EarnCategoryCreate />} />
                  <Route path="edit/:id" element={<EarnCategoryEdit />} />
                  <Route path="show/:id" element={<EarnCategoryShow />} />
                </Route>

                <Route path="/earn-user-history">
                  <Route index element={<EarnUserHistoryList />} />
                  <Route path="show/:id" element={<EarnUserHistoryShow />} />
                </Route>
                <Route path="/wallet-configs">
                  <Route index element={<WalletConfigList />} />
                </Route>
                <Route path="/game">
                  <Route index element={<GameList />} />
                  <Route path="show/:id" element={<GameShow />} />
                  <Route path="edit/:id" element={<GameEdit />} />
                  <Route path="create" element={<GameCreate />} />
                </Route>
                <Route path="/game-category">
                  <Route index element={<GameCategoryList />} />
                  <Route path="edit/:id" element={<GameCategoryEdit />} />
                  <Route path="show/:id" element={<GameCategoryShow />} />
                  <Route path="create" element={<GameCategoryCreate />} />
                </Route>

                <Route path="/partner">
                  <Route index element={<PartnerList />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="create" element={<PartnerCreate />} />
                </Route>

                <Route path="/lucky-wheel-reward">
                  <Route index element={<LuckyWheelRewardList />} />
                  <Route path="edit/:id" element={<LuckyWheelRewardEdit />} />
                  <Route path="show/:id" element={<LuckyWheelRewardShow />} />
                  <Route path="create" element={<LuckyWheelRewardCreate />} />
                </Route>

                <Route path="/lucky-wheel-mission">
                  <Route index element={<LuckyWheelMissionList />} />
                  <Route path="edit/:id" element={<LuckyWheelMissionEdit />} />
                  <Route path="show/:id" element={<LuckyWheelMissionShow />} />
                </Route>

                <Route path="/ota-reward-batch">
                  <Route index element={<OtaRewardBatchList />} />
                  <Route path="show/:id" element={<OtaRewardBatchShow />} />
                  <Route path="create" element={<OtaRewardBatchCreate />} />
                  <Route path="edit/:id" element={<OtaRewardBatchEdit />} />
                </Route>

                <Route path="/user-data-migration">
                  <Route index element={<UserDataMigrationList />} />
                  <Route path="show/:id" element={<UserDataMigrationShow />} />
                  <Route path="create" element={<UserDataMigrationCreate />} />
                </Route>

                <Route path="/top-10-energy-stone">
                  <Route index element={<Top10EnergyStoneList />} />
                </Route>
                <Route path="/push-notification">
                  <Route index element={<PushNotificationList />} />
                  <Route path="create" element={<PushNotificationCreate />} />
                  <Route path="edit/:id" element={<PushNotificationEdit />} />
                  <Route path="show/:id" element={<PushNotificationShow />} />
                </Route>

                <Route path="/lottery-global-config">
                  <Route index element={<LotteryGlobalConfig />} />
                </Route>
                <Route path="/lottery-ratio-config">
                  <Route index element={<LotteryRatioConfig />} />
                </Route>
                <Route path="/lottery-realtime-dashboard">
                  <Route index element={<LotteryRealtimeDashboard />} />
                </Route>
                <Route path="/lottery-result">
                  <Route index element={<LotteryResult />} />
                </Route>

                <Route path="/football-fest">
                  <Route path="match" element={<FootballFestMatchList />} />
                  <Route
                    path="match/show/:id"
                    element={<FootballFestMatchShow />}
                  />
                  <Route
                    path="category"
                    element={<FootballFestCategoryList />}
                  />
                  <Route
                    path="category/show/:id"
                    element={<FootballFestCategoryShow />}
                  />
                </Route>
              </Route>

              <Route
                element={
                  <Authenticated
                    fallback={<Outlet />}
                    key={'NavigateToResource'}
                  >
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
              </Route>
              <Route
                element={
                  <Authenticated key={'ThemedLayoutV2'}>
                    <ThemedLayoutV2 Header={Header}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
