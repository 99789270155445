import { DateField } from '@refinedev/antd';
import { DEFAULT_TIMEZONE } from '../../constants';
import moment from 'moment-timezone';

interface DateFieldProps {
  value: Date;
}

export const DateFieldTimezone: React.FC<DateFieldProps> = (
  props: DateFieldProps,
) => {
  return (
    <div>
      <DateField
        format="DD/MM/YYYY"
        value={moment(props.value).tz(DEFAULT_TIMEZONE).toString()}
      />
    </div>
  );
};
