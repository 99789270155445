import React from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  NumberField,
} from '@refinedev/antd';
import { Table, Space, Tag } from 'antd';
import { LuckyWheelRewardType, TypeTagColor } from './constant';

export const LuckyWheelRewardList: React.FC<
  IResourceComponentsProps<{
    id: number;
    name: string;
    amount: number;
    threshold: number;
    type: LuckyWheelRewardType;
  }>
> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="amount" title="Amount" />
        <Table.Column
          dataIndex="type"
          title="Type"
          render={(value: LuckyWheelRewardType) => (
            <Tag color={TypeTagColor[value]}>{value}</Tag>
          )}
        />
        <Table.Column
          dataIndex="threshold"
          title="Threshold"
          render={(value: number) => (
            <NumberField
              value={value}
              options={{
                style: 'percent',
              }}
            />
          )}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
