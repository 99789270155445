import React from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useNotification,
} from '@refinedev/core';
import { getValueFromEvent, useForm, useSelect } from '@refinedev/antd';
import { Breadcrumb, Button, Card, Col, Form, Row, Select, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/lib/upload';

export const UserMigrateCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, onFinish } = useForm({
    errorNotification: (error) => {
      return {
        message: error?.message || '',
        description: error?.message || '',
        type: 'error',
      };
    },
  });

  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');

  const { selectProps: userSelectProps } = useSelect({
    resource: 'users',
    optionLabel: 'email',
    optionValue: 'email',
  });

  const onHandleFinish = (values: any) => {
    if (values.csvFile) {
      const fileUrl = values.csvFile[0].response.url;
      const filename = fileUrl.substring(
        fileUrl.lastIndexOf('/') + 1,
        fileUrl.lastIndexOf('.'),
      );

      // uploading file
      onFinish({
        group: filename,
        pairs: values.csvFile[0].response.data,
      });

      return;
    }

    onFinish({
      pairs: [
        {
          from: values.from,
          to: values.to,
        },
      ],
    });
  };

  const tailLayout = {
    wrapperCol: { offset: 11, span: 16 },
  };

  const { open } = useNotification();

  const onChangeFileUpload = (
    info: UploadChangeParam<
      UploadFile<{
        url: string;
        data: {
          from: string;
          to: string;
        }[];
      }>
    >,
  ) => {
    const { status } = info.file;

    if (status === 'done') {
      open?.({
        type: 'success',
        message: 'Upload Success',
      });

      return;
    }

    if (status === 'error') {
      open?.({
        type: 'error',
        message: 'Upload Failed',
      });

      return;
    }
  };

  const renderInput = () => {
    return (
      <Form {...formProps} onFinish={onHandleFinish} layout="vertical">
        <Form.Item label="From User" name="from">
          <Select {...userSelectProps} />
        </Form.Item>
        <Form.Item label="To User" name="to">
          <Select {...userSelectProps} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderCsv = () => {
    return (
      <Form
        {...formProps}
        onFinish={onHandleFinish}
        layout="vertical"
        style={{ paddingBottom: 55 }}
      >
        <Form.Item label="File CSV">
          <Form.Item
            name="csvFile"
            getValueFromEvent={getValueFromEvent}
            valuePropName="fileList"
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/user-migrate/upload-file`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              maxCount={1}
              multiple
              onChange={onChangeFileUpload}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit File
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <a href="/user-migrate">Migration users</a>,
          },
          { title: 'Create' },
        ]}
        style={{ marginBottom: 16 }}
      />
      <Row gutter={16}>
        <Col span={12}>
          <Card
            title="Input email manually"
            bordered={false}
            style={{ height: '100%' }}
          >
            {renderInput()}
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title="Input file CSV"
            bordered={false}
            style={{ height: '100%' }}
          >
            {renderCsv()}
          </Card>
        </Col>
      </Row>
    </>
  );
};
