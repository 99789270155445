import { Card, Divider, Form, Input, Spin, Typography } from 'antd';
import {
  useApiUrl,
  useCustom,
  useCustomMutation,
  useNotification,
} from '@refinedev/core';
import { Fragment, useMemo } from 'react';
import { upperCase } from 'lodash';
import { Breadcrumb, PageHeader, SaveButton } from '@refinedev/antd';
import { Decimal } from 'decimal.js';
import { valueType } from 'antd/es/statistic/utils';
import { blue } from '@ant-design/colors';
import { getValueFromConfig } from './helper';

export const LotteryGlobalConfig = () => {
  const { open } = useNotification();

  const [form] = Form.useForm<{
    config: {
      code: string;
      value: string;
    }[];
  }>();
  const config = Form.useWatch('config', form);

  const apiUrl = useApiUrl();
  const { data, isLoading } = useCustom<
    {
      id: string;
      code: string;
      value: string;
    }[]
  >({
    url: `${apiUrl}/lottery/global-config`,
    method: 'get',
  });

  const { mutateAsync, isLoading: isMutating } = useCustomMutation();

  const onFinish = async (data: {
    config: { code: string; value: string }[];
  }) => {
    await mutateAsync({
      url: `${apiUrl}/lottery/global-config`,
      method: 'put',
      values: data,
    });

    if (open) {
      open({
        type: 'success',
        message: 'Successfully edited lottery global config',
        description: 'Successful',
      });
    }
  };

  const dailyTicketQuota = useMemo(() => {
    const dailyActiveUser = getValueFromConfig(config, 'capped_dau');

    const dailyTicketPerUser =
      getValueFromConfig(config, 'daily_ticket_per_user') || 24;

    return new Decimal(dailyActiveUser).mul(dailyTicketPerUser).toNumber();
  }, [config]);

  const ticketPerSession = useMemo(() => {
    const minutesPerDay = 1440; // 1440 minutes per day

    const sessionDuration = getValueFromConfig(config, 'session_duration');

    const dailyTicketPerUser =
      getValueFromConfig(config, 'daily_ticket_per_user') || 24;

    return new Decimal(minutesPerDay)
      .div(sessionDuration)
      .div(dailyTicketPerUser)
      .toNumber();
  }, [config]);

  const calculations = useMemo<
    {
      label: string;
      value: valueType;
    }[]
  >(
    () => [
      {
        label: 'Daily Ticket Quota',
        value: dailyTicketQuota,
      },
      {
        label: 'Ticket Per Session',
        value: ticketPerSession,
      },
    ],
    [dailyTicketQuota, ticketPerSession],
  );

  return (
    <PageHeader
      title={'Lottery Global Config'}
      backIcon={null}
      breadcrumb={<Breadcrumb />}
    >
      <Spin spinning={isLoading}>
        <Card
          bordered={false}
          actions={[
            <SaveButton
              style={{ float: 'right', marginRight: '24px' }}
              onClick={() => {
                form.submit();
              }}
              loading={isMutating}
            />,
          ]}
        >
          <Form
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            form={form}
            onFinish={onFinish}
          >
            {(Array.isArray(data?.data) ? data!.data : []).map(
              (config, index) => (
                <Fragment key={config.code}>
                  <Form.Item
                    name={['config', index, 'code']}
                    initialValue={config.code}
                    hidden
                  />
                  <Form.Item
                    label={upperCase(config.code)}
                    labelAlign="left"
                    name={['config', index, 'value']}
                    initialValue={config.value}
                    rules={[
                      () => ({
                        validator(_, value) {
                          console.log(value, config.code);
                          if (config.code === 'notify_cron_range_time') {
                            if (!JSON.parse(value)) {
                              return Promise.reject(
                                new Error(
                                  'This config need to be a String or JSON',
                                ),
                              );
                            }
                          } else if (isNaN(value)) {
                            return Promise.reject(
                              new Error('This config need to be a number'),
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Fragment>
              ),
            )}
            <Divider />
            <div
              style={{
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: 'max-content 1fr',
                gap: '12px',
              }}
            >
              {calculations.map(({ label, value }) => (
                <>
                  <Typography.Title
                    level={3}
                    style={{
                      margin: 0,
                    }}
                  >
                    {label}:
                  </Typography.Title>
                  <Typography.Title
                    level={3}
                    style={{
                      margin: 0,
                      color: blue[6],
                    }}
                  >
                    {value.toLocaleString()}
                  </Typography.Title>
                </>
              ))}
            </div>
          </Form>
        </Card>
      </Spin>
    </PageHeader>
  );
};
