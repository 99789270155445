import React from 'react';
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from '@refinedev/core';
import { Show, NumberField, TextField } from '@refinedev/antd';
import { Typography } from 'antd';

const { Title } = Typography;

export const CampaignRewardShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>ID</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Earn Campaign Name</Title>
      <TextField value={record?.earnCampaignName} />
      <Title level={5}>Reward Type</Title>
      <TextField value={record?.rewardType} />
      <Title level={5}>Reward Value</Title>
      <NumberField value={record?.rewardValue ?? ''} />
    </Show>
  );
};
