import React, { useCallback, useState } from 'react';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
} from '@refinedev/core';
import { List, useSelect } from '@refinedev/antd';
import {
  Table,
  FormProps,
  Button,
  Form,
  Input,
  Select,
  Collapse,
  Tag,
  Radio,
  InputNumber,
  Flex,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { handleFilter } from '@refinedev/nestjsx-crud';
const { Panel } = Collapse;

export const CloudwatchList: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const [query, setQuery] = useState<Record<string, any> | undefined>();
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const { data, isFetching } = useCustom({
    url: `${apiUrl}/cloudwatch/logs`,
    method: 'get',
    config: {
      query: {
        ...query,
        nextToken: nextTokens[nextTokens.length - 1],
      },
    },
  });

  const [form] = Form.useForm();

  const onFinish = useCallback((values: any) => {
    const { q, time, logGroups, line, otherTime } = values;

    let newQuery = RequestQueryBuilder.create();

    newQuery = handleFilter(newQuery, [
      {
        field: 'q',
        operator: 'eq',
        value: q,
      },
      {
        field: 'time',
        operator: 'eq',
        value: time === 'other' ? Number(otherTime) : Number(time),
      },
      {
        field: 'logGroups',
        operator: 'eq',
        value: logGroups,
      },
      {
        field: 'line',
        operator: 'eq',
        value: line,
      },
    ]);

    setQuery(newQuery.queryObject);
    setNextTokens([]);
  }, []);

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <Flex vertical gap={'16px'}>
      <Collapse
        activeKey={['1']}
        defaultActiveKey={['0']}
        onChange={onChange}
        style={{ marginBottom: 16 }}
      >
        <Panel header="Filter" key="1">
          <Filter
            formProps={{
              form: form,
              onFinish,
            }}
          />
        </Panel>
      </Collapse>
      <List>
        <Table
          rowKey="eventId"
          loading={isFetching}
          dataSource={data?.data.data ?? []}
          pagination={false}
        >
          <Table.Column
            title="Time"
            dataIndex="timestamp"
            render={(value: string) => (
              <Tag color="brown">
                {new Date(value).toLocaleString('vi-VN', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })}
              </Tag>
            )}
            width={180}
            sorter={{ multiple: 1 }}
          />
          <Table.Column title="Message" dataIndex="message" />
        </Table>
      </List>
      <Flex justify="flex-end" gap={'8px'}>
        <Button
          disabled={!nextTokens.length || isFetching}
          onClick={() => {
            setNextTokens((nextTokens) => [
              ...nextTokens.slice(0, nextTokens.length - 1),
            ]);
          }}
        >
          Previous
        </Button>
        <Button
          disabled={
            !data?.data.nextToken ||
            isFetching ||
            (data?.data.data ?? []).length === 0
          }
          onClick={() => {
            setNextTokens((nextTokens) => [
              ...nextTokens,
              data?.data.nextToken,
            ]);
          }}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const { selectProps: logGroupsSelectProps } = useSelect({
    resource: 'cloudwatch/groups',
    optionLabel: 'logGroupName',
    optionValue: 'logGroupName',
  });

  const watchTime = Form.useWatch('time', formProps.form);

  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Log Groups" name="logGroups">
        <Select
          allowClear
          options={logGroupsSelectProps.options}
          placeholder="Select log groups"
        />
      </Form.Item>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="Text search, correlationId, etc."
          prefix={<SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Since" name="time">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio.Button value="5">5m</Radio.Button>
          <Radio.Button value="30">30m</Radio.Button>
          <Radio.Button value="60">60m</Radio.Button>
          <Radio.Button value="120">2h</Radio.Button>
          <Radio.Button value="1440">1d</Radio.Button>
          <Radio.Button value="10080">7d</Radio.Button>
          <Radio.Button value="43200">30d</Radio.Button>
          <Radio.Button value="other">Custom</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {watchTime === 'other' && (
        <Form.Item
          name="otherTime"
          required={watchTime === 'other'}
          label="Time in minute"
        >
          <InputNumber />
        </Form.Item>
      )}
      <Form.Item label="Limit line" name="line">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio.Button value="25">25 line</Radio.Button>
          <Radio.Button value="50">50 line</Radio.Button>
          <Radio.Button value="100">100 line</Radio.Button>
          <Radio.Button value="150">150 line</Radio.Button>
          <Radio.Button value="300">300 line</Radio.Button>
          <Radio.Button value="500">500 line</Radio.Button>
          <Radio.Button value="1000">1000 line</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item hidden name="nextToken">
        {/* <Input value={} /> */}
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          style={{ backgroundColor: 'brown' }}
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};
