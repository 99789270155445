import React, { useState } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useTranslate,
} from '@refinedev/core';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Checkbox, DatePicker, Select, Upload } from 'antd';
import dayjs from 'dayjs';
import {
  COUNTRY_CODE_OPTION,
  NOTIFICATION_DEEP_LINK_OPTION,
  NOTIFICATION_HOURS_OPTION,
  NOTIFICATION_MINUTE_OPTION,
  NOTIFICATION_PLATFORM_OPTION,
  NOTIFICATION_TYPE_OPTION,
} from '../../constants';
import { getDataFile } from '../../utils/upload-file';

export const PushNotificationCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps } = useForm();
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isHiddenLinkWeb, setIsHiddenLinkWeb] = useState<boolean>(true);

  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');

  const { selectProps: userSelectProps } = useSelect({
    resource: 'users',
    optionLabel: 'email',
    optionValue: 'id',
  });

  const disabledDate = (current: any) => {
    return current && current < dayjs().startOf('day');
  };
  const onChangeType = (value: string) => {
    if (value === 'push_now') {
      setIsHidden(true);
    } else setIsHidden(false);
  };
  const onChangeDeepLink = (value: string) => {
    console.log(value);
    if (value !== 'web') {
      setIsHiddenLinkWeb(true);
    } else setIsHiddenLinkWeb(false);
  };
  const onChangeUser = (value: any) => {
    console.log('onChangeUser', value);
  };
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={translate('Topic')}
          name={['topic']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('Campaign Name')}
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('VN Title')}
          name={['vnTitle']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate(' VN Message')}
          name={['vnMessage']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('EN Title')}
          name={['enTitle']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('EN Message')}
          name={['enMessage']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={translate('Type')} name={['type']}>
          <Select
            options={NOTIFICATION_TYPE_OPTION}
            defaultValue={NOTIFICATION_TYPE_OPTION[0].value}
            onChange={onChangeType}
          />
        </Form.Item>
        <Form.Item
          hidden={isHidden}
          label={translate('Hour')}
          name={['hour']}
          rules={[
            {
              required: !isHidden,
            },
          ]}
        >
          <Select options={NOTIFICATION_HOURS_OPTION} />
        </Form.Item>
        <Form.Item
          hidden={isHidden}
          label={translate('Minute')}
          name={['minute']}
          rules={[
            {
              required: !isHidden,
            },
          ]}
        >
          <Select options={NOTIFICATION_MINUTE_OPTION} />
        </Form.Item>
        <Form.Item
          hidden={isHidden}
          label={translate('Start At')}
          name={['startAt']}
          rules={[
            {
              required: !isHidden,
            },
          ]}
        >
          <DatePicker format={'DD/MM/YYYY'} disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item
          hidden={isHidden}
          label={translate('End At')}
          name={['endAt']}
          rules={[
            {
              required: !isHidden,
            },
          ]}
        >
          <DatePicker format={'DD/MM/YYYY'} disabledDate={disabledDate} />
        </Form.Item>

        <Form.Item
          label={translate('Status')}
          valuePropName="checked"
          name={['isActive']}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
        <Form.Item label={translate('DeepLink')} name={['deepLink']}>
          <Select
            options={NOTIFICATION_DEEP_LINK_OPTION}
            defaultValue={NOTIFICATION_DEEP_LINK_OPTION[0].value}
            onChange={onChangeDeepLink}
          />
        </Form.Item>
        <Form.Item
          hidden={isHiddenLinkWeb}
          label={translate('Link Web')}
          name={['linkWeb']}
          rules={[
            {
              required: !isHiddenLinkWeb,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={translate('Platform')} name={['platform']}>
          <Select
            options={NOTIFICATION_PLATFORM_OPTION}
            defaultValue={NOTIFICATION_PLATFORM_OPTION[0]}
          />
        </Form.Item>
        <Form.Item label={translate('Countries')} name={['countries']}>
          <Select
            options={COUNTRY_CODE_OPTION}
            filterOption={true}
            mode="multiple"
            allowClear
            optionFilterProp={'label'}
          />
        </Form.Item>
        <Form.Item
          label={translate('Exclude Countries')}
          name={['excludeCountries']}
        >
          <Select
            options={COUNTRY_CODE_OPTION}
            filterOption={true}
            mode="multiple"
            allowClear
            optionFilterProp={'label'}
          />
        </Form.Item>
        <div
          style={{
            backgroundColor: '#f1f1f1',
            padding: '20px',
            border: '1px solid #d9d9d9',
            borderRadius: '4px',
          }}
        >
          <Form.Item
            label="List User ID"
            name={['listUserIds']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              {...userSelectProps}
              filterOption={true}
              mode="multiple"
              allowClear
              optionFilterProp={'label'}
              onChange={onChangeUser}
            />
          </Form.Item>
          <Form.Item label="or CSV File">
            <Form.Item
              name="csvListUserIds"
              getValueFromEvent={getDataFile}
              noStyle
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Upload.Dragger
                listType="picture"
                name="file"
                action={`${apiUrl}/push-notification/upload`}
                headers={{
                  Authorization: `Bearer ${token}`,
                }}
                maxCount={1}
              >
                <p className="ant-upload-text">
                  Drag & drop a file in this area
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </div>
      </Form>
    </Create>
  );
};
