import React, { useState } from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  CrudFilters,
  useShow,
} from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  ImageField,
  Show,
  BooleanField,
} from '@refinedev/antd';
import {
  Table,
  Space,
  Tag,
  Button,
  Form,
  FormProps,
  Input,
  Collapse,
  Drawer,
} from 'antd';

import { DateFieldTimezone } from '../../components/table/date-field';
import { HyperLinkField } from 'components/table';
import { USERS_LINK } from '../../constants';
import { SearchOutlined } from '@ant-design/icons';
import { DrawerKycLv2Info } from './kyc-lv2-drawer';
import { IKycLv2Detail } from '../../interfaces/kyc-lv2';

const { Panel } = Collapse;

export const KycLv2List: React.FC<IResourceComponentsProps> = () => {
  const [isShowDrawerVisible, setIsShowDrawerVisible] = useState(false);
  const { queryResult, showId, setShowId } = useShow<IKycLv2Detail>();
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch: (params: any) => {
      let filters: CrudFilters = [];
      const { q, userId, userManuallyDocument } = params;

      filters = [
        {
          field: 'q',
          operator: 'eq',
          value: q,
        },
        {
          field: 'userId',
          operator: 'eq',
          value: userId,
        },
        {
          field: 'userManuallyDocument',
          operator: 'eq',
          value: userManuallyDocument,
        },
      ];

      return filters;
    },
  });
  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  return (
    <>
      <Collapse
        defaultActiveKey={['1']}
        onChange={onChange}
        style={{ marginBottom: 16 }}
      >
        <Panel header="Filter" key="1">
          <Filter formProps={searchFormProps} />
        </Panel>
      </Collapse>

      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="userId"
            title="User ID"
            render={(value: string) => (
              <HyperLinkField value={value} link={USERS_LINK} />
            )}
          />
          <Table.Column
            dataIndex="user"
            title="Email"
            render={(value: any) => <Tag color="geekblue">{value.email}</Tag>}
          />
          <Table.Column dataIndex="fullName" title="Full Name" />
          <Table.Column
            dataIndex={['imageFront']}
            title="Image Front"
            render={(value: any) => (
              <ImageField style={{ maxWidth: '100px' }} value={value} />
            )}
          />
          <Table.Column
            dataIndex={['imageFace']}
            title="Image Face"
            render={(value: any) => (
              <ImageField style={{ maxWidth: '100px' }} value={value} />
            )}
          />
          <Table.Column dataIndex="country" title="Country" />
          <Table.Column dataIndex="documentType" title="Document Type" />

          <Table.Column
            dataIndex={[
              'identityNumber',
              'passportNumber',
              'driverLicenseNumber',
              'otherNumber',
            ]}
            title="Document Number"
            render={(value: any, record: any) => {
              if (record.identityNumber)
                return <Tag color="geekblue">{record.identityNumber}</Tag>;
              if (record.passportNumber)
                return <Tag color="green">{record.passportNumber}</Tag>;
              if (record.driverLicenseNumber)
                return <Tag color="red">{record.driverLicenseNumber}</Tag>;
              if (record.otherNumber)
                return <Tag color="red">{record.otherNumber}</Tag>;
            }}
          />
          <Table.Column
            dataIndex={['isActive']}
            title="Status"
            render={(value: any) =>
              value ? (
                <Tag color="blue">Approved</Tag>
              ) : (
                <Tag color="red">Waiting for approval</Tag>
              )
            }
          />
          <Table.Column
            dataIndex="description"
            title="description"
            render={(value: any) => {
              if (value === 'isManualCheck')
                return <Tag color="red">{value}</Tag>;
              if (value === 'isUserManually')
                return <Tag color="purple">{value}</Tag>;
              return <Tag color="geekblue">{value}</Tag>;
            }}
          />
          <Table.Column
            dataIndex={['createdAt']}
            title="Created At"
            render={(value: any) => <DateFieldTimezone value={value} />}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  onClick={() => {
                    setShowId(record.id);
                    setIsShowDrawerVisible(true);
                  }}
                />
              </Space>
            )}
          />
        </Table>
      </List>
      <Drawer
        open={isShowDrawerVisible}
        onClose={() => setIsShowDrawerVisible(false)}
        width="70%"
      >
        <Show isLoading={!queryResult.data?.data} recordItemId={showId}>
          {queryResult.data?.data && (
            <DrawerKycLv2Info kycLv2Detail={queryResult.data.data} />
          )}
        </Show>
      </Drawer>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="Email, Name, fullName, Identity Number, Passport Number, driver's License Number etc."
          prefix={<SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Only User ID" name="userId">
        <Input
          placeholder="User ID"
          prefix={<SearchOutlined />}
          type="number"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
