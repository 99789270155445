import React from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  CrudFilters,
} from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  BooleanField,
  useSelect,
} from '@refinedev/antd';
import {
  Table,
  Space,
  Typography,
  Button,
  Form,
  Select,
  Collapse,
  FormProps,
} from 'antd';

const { Panel } = Collapse;

export const EarnWidgetList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch: (params: any) => {
      let filters: CrudFilters = [];
      const { earnCampaignId } = params;

      filters = [
        {
          field: 'earnCampaignId',
          operator: 'eq',
          value: earnCampaignId,
        },
      ];

      return filters;
    },
  });

  return (
    <>
      <Collapse defaultActiveKey={['0']} style={{ marginBottom: 16 }}>
        <Panel header="Filter" key="1">
          <Filter formProps={searchFormProps} />
        </Panel>
      </Collapse>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            dataIndex="earnCampaignName"
            title="Earn Campaign Name"
          />
          <Table.Column dataIndex="languageCode" title="Language Code" />
          <Table.Column dataIndex="description" title="Description" />

          <Table.Column
            dataIndex={['isActive']}
            title="Is Active"
            render={(value: any) => <BooleanField value={value} />}
          />

          <Table.Column
            dataIndex="actionFlow"
            title="Action Flow"
            render={(value: any) =>
              value?.map((item: any) => (
                <>
                  <Typography.Text code={true} editable={false}>
                    {item.step}: {item.description}
                  </Typography.Text>
                  <br />
                </>
              ))
            }
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const { selectProps: earnCampaignSelectProps } = useSelect({
    resource: 'earn-campaign',
    optionLabel: 'name',
    pagination: {
      pageSize: 100,
      mode: 'server',
    },
  });
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Earn Campaign" name="earnCampaignId">
        <Select {...earnCampaignSelectProps} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
