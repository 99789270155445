import React from 'react';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import {
  Show,
  NumberField,
  TextField,
  ImageField,
  DateField,
  BooleanField,
} from '@refinedev/antd';
import { Col, Flex, Row, Tree, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { parseJsonToTree } from '../../utils/object';

const { Title } = Typography;

export const FootballFestMatchShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();

  const { queryResult } = useShow({
    resource: 'football-fest/match',
    id,
  });

  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading} canEdit={false}>
      <Flex vertical gap={20}>
        <div>
          <Title level={4}>Id</Title>
          <TextField value={record?.id ?? ''} />
        </div>
        <Row>
          <Col
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Title level={4}>Team A</Title>
            <TextField value={record?.teamA} />
            <ImageField
              style={{ maxWidth: 200 }}
              value={record?.teamAIconUrl}
            />
          </Col>
          <Col
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Title level={4}>Team B</Title>
            <TextField value={record?.teamB} />
            <ImageField
              style={{ maxWidth: 200 }}
              value={record?.teamBIconUrl}
            />
          </Col>
        </Row>
        <div>
          <Title level={4}>Location</Title>
          <TextField value={record?.location} />
        </div>
        <div>
          <Title level={4}>Start time</Title>
          <DateField value={record?.startTime} format="MM/DD/YYYY HH:mm:ss" />
        </div>
        <div>
          <Title level={4}>End time</Title>
          <DateField value={record?.endTime} format="MM/DD/YYYY HH:mm:ss" />
        </div>
        <div>
          <Title level={4}>Can match have draw result?</Title>
          <BooleanField value={record?.canResultBeDraw} />
        </div>
        <div>
          <Title level={4}>Score</Title>
          <TextField
            value={`${record?.teamAScore ?? ''} - ${record?.teamBScore ?? ''}`}
          />
        </div>
        <div>
          <Title level={4}>Penalty</Title>
          <TextField
            value={`${record?.teamAPenalty ?? ''} - ${
              record?.teamBPenalty ?? ''
            }`}
          />
        </div>
        <div>
          <Title level={4}>Pool Amount</Title>
          <NumberField value={record?.poolAmount ?? ''} />
        </div>
        <div>
          <Title level={4}>Pool Type</Title>
          <TextField value={record?.poolType} />
        </div>
        <div>
          <Title level={4}>Display Priority</Title>
          <NumberField value={record?.displayPriority ?? ''} />
        </div>
        <div>
          <Title level={4}>Round</Title>
          <TextField value={record?.round} />
        </div>
        <div>
          <Title level={4}>Created at</Title>
          <DateField value={record?.createdAt} format="MM/DD/YYYY HH:mm:ss" />
        </div>
        <div>
          <Title level={4}>Updated at</Title>
          <DateField value={record?.updatedAt} format="MM/DD/YYYY HH:mm:ss" />
        </div>
        <div>
          <Title level={4}>Leagues/Category</Title>
          <TextField value={record?.category?.name} />
        </div>
        {!!record?.lineUps && (
          <div>
            <Title level={4}>Lineups raw data</Title>
            <Tree treeData={parseJsonToTree(record.lineUps)} />
          </div>
        )}

        {!!record?.stats && (
          <div>
            <Title level={4}>Statistics raw data</Title>
            <Tree treeData={parseJsonToTree(record.stats)} />
          </div>
        )}
      </Flex>
    </Show>
  );
};
