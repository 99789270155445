import { TreeDataNode } from 'antd';

export const parseJsonToTree = (
  data: any,
  path: number[] = [],
): TreeDataNode[] => {
  if (!data) return [];

  if (typeof data !== 'object') {
    return [
      {
        key: [...path, 0].join('-'),
        title: data,
      },
    ];
  }

  if (Array.isArray(data)) {
    return data.map((value, index) => {
      if (typeof value !== 'object') {
        return {
          key: [...path, index].join('-'),
          title: value,
        };
      }

      return {
        key: [...path, index].join('-'),
        title: index,
        children: parseJsonToTree(value, [...path, index]),
      };
    });
  }

  return Object.entries(data).map(([key, value], index) => {
    if (typeof value !== 'object') {
      return {
        key: [...path, index].join('-'),
        title: `${key}: ${value}`,
      };
    }

    return {
      key: [...path, index].join('-'),
      title: key,
      children: parseJsonToTree(value, [...path, index]),
    };
  });
};
