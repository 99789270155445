import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Form,
  InputNumber,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import {
  useApiUrl,
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useNotification,
  useShow,
} from '@refinedev/core';
import { isNumber } from 'lodash';
import {
  Breadcrumb,
  NumberField,
  PageHeader,
  SaveButton,
  Show,
  ShowButton,
} from '@refinedev/antd';
import { blue, red } from '@ant-design/colors';
import { DateField, useTable } from '@refinedev/antd';
import { DateFieldTimezone } from '../../components/table/';
import { useState } from 'react';
import { RedEnvelopeOutlined } from '@ant-design/icons';
import { ShowReport } from './showReport';

type IUser = {
  id: number;
  name: string;
  avatar: string;
  role: string;
};

export const LotteryResult = () => {
  const { open } = useNotification();
  const { data: user } = useGetIdentity<IUser>();
  const [showId, setShowId] = useState<any>();
  const [isShowDrawerVisible, setIsShowDrawerVisible] = useState(false);

  const [form] = Form.useForm<{
    config: {
      code: string;
      value: string;
    }[];
  }>();
  const apiUrl = useApiUrl();
  const { data: currentDraw, isLoading } = useCustom<any>({
    url: `${apiUrl}/lottery/current-draw`,
    method: 'get',
  });

  const { mutateAsync, isLoading: isMutating } = useCustomMutation();

  const checkDuplicates = (numbers: number[]) => {
    const seen = new Set();
    for (const num of numbers) {
      if (seen.has(num)) {
        return false;
      }
      seen.add(num);
    }
    return true;
  };

  const onFinish = async (data: any) => {
    const { no01, no02, no03, no04, no05, jackpotNumber } = data;
    const normalNumber = [no01, no02, no03, no04, no05];
    const winningNumber = {
      normalNumber,
      jackpotNumber,
      jackpotDrawId: currentDraw?.data?.id,
    };
    const isCheckDuplication = checkDuplicates(normalNumber);
    if (!isCheckDuplication) {
      if (open) {
        open({
          type: 'error',
          message: 'NORMAL NUMBER MUST BE UNIQUE',
          description: 'Normal number input failed',
        });
      }
      return;
    }

    if (isNumber(jackpotNumber) === false) {
      if (open) {
        open({
          type: 'error',
          message: 'JACKPOT NUMBER MUST BE INPUT',
          description: 'Jackpot number input failed',
        });
      }
      return;
    }
    if (normalNumber.length !== 5) {
      if (open) {
        open({
          type: 'error',
          message: 'NORMAL NUMBER MUST BE INPUT',
          description: 'NORMAL number input failed',
        });
      }
      return;
    }
    await mutateAsync({
      url: `${apiUrl}/lottery/winning-number-result`,
      method: 'post',
      values: winningNumber,
    });
    if (open) {
      open({
        type: 'success',
        message: 'Successfully edited lottery global config',
        description: 'Successful',
      });
    }
  };

  const { tableProps: listRequestedProps } = useTable({
    syncWithLocation: true,
    resource: `lottery/draw`,
  });

  const onRewardClick = async (drawId: number) => {
    console.log('drawId', drawId);
    await mutateAsync({
      url: `${apiUrl}/lottery/reward-ticket`,
      method: 'post',
      values: {
        drawId,
      },
    });
    if (open) {
      open({
        type: 'success',
        message: 'Successfully edited lottery global config',
        description: 'Successful',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const getReport = (drawId: number) => {
    setShowId(drawId);
    setIsShowDrawerVisible(true);
  };

  return (
    <>
      <PageHeader
        title={'Lottery Result'}
        backIcon={null}
        breadcrumb={<Breadcrumb />}
      >
        <Spin spinning={isLoading}>
          {user?.role === 'superadmin' ? (
            <>
              <Card
                bordered={false}
                actions={[
                  <SaveButton
                    style={{ float: 'right', marginRight: '24px' }}
                    onClick={() => {
                      form.submit();
                    }}
                    loading={isMutating}
                  />,
                ]}
              >
                <Form
                  labelCol={{ span: 3 }}
                  wrapperCol={{ span: 21 }}
                  form={form}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col span={24}>
                      <Typography.Title level={5}>
                        Input Number of Draw - #{currentDraw?.data?.id} - Start:{' '}
                        {
                          <DateField
                            format="DD/MM/YYYY"
                            value={currentDraw?.data?.sessionStartAt}
                          />
                        }{' '}
                        - End:{' '}
                        {
                          <DateField
                            format="DD/MM/YYYY"
                            value={currentDraw?.data?.sessionEndAt}
                          />
                        }
                      </Typography.Title>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={4}>
                      <Form.Item labelAlign="left" name={`no01`}>
                        <InputNumber
                          placeholder="Normal Number 1"
                          style={{
                            width: '100%',
                            borderColor: blue.primary,
                            borderWidth: 2,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item labelAlign="left" name={`no02`}>
                        <InputNumber
                          placeholder="Normal Number 2"
                          style={{
                            width: '100%',
                            borderColor: blue.primary,
                            borderWidth: 2,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item labelAlign="left" name={`no03`}>
                        <InputNumber
                          placeholder="Normal Number 3"
                          style={{
                            width: '100%',
                            borderColor: blue.primary,
                            borderWidth: 2,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item labelAlign="left" name={`no04`}>
                        <InputNumber
                          placeholder="Normal Number 4"
                          style={{
                            width: '100%',
                            borderColor: blue.primary,
                            borderWidth: 2,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item labelAlign="left" name={`no05`}>
                        <InputNumber
                          placeholder="Normal Number 5"
                          style={{
                            width: '100%',
                            borderColor: blue.primary,
                            borderWidth: 2,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item labelAlign="left" name={`jackpotNumber`}>
                        <InputNumber
                          placeholder="Jackpot Number"
                          style={{
                            width: '100%',
                            borderColor: red.primary,
                            borderWidth: 2,
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </>
          ) : (
            <></>
          )}
        </Spin>
        <Divider />
        <div>
          <Table
            {...listRequestedProps}
            rowKey="Id"
            sticky={{ offsetHeader: 0 }}
          >
            <Table.Column dataIndex="id" title={'ID'} />
            <Table.Column
              dataIndex="winningNumbers"
              title={'Winning Number'}
              render={(value: any) =>
                value.length > 0 ? (
                  <>
                    {value.map((i: any) => (
                      <Tag
                        color="blue"
                        style={{
                          fontWeight: 700,
                          width: '30px',
                          textAlign: 'center',
                        }}
                      >
                        {i}
                      </Tag>
                    ))}
                  </>
                ) : (
                  <>[]</>
                )
              }
              width={250}
            />
            <Table.Column
              dataIndex="jackpotNumber"
              title={'Jackpot Number'}
              width={200}
              render={(value: any) => (
                <Tag
                  color="red"
                  style={{
                    fontWeight: 700,
                    width: '30px',
                    textAlign: 'center',
                  }}
                >
                  {value}
                </Tag>
              )}
            />
            <Table.Column
              dataIndex="totalPrize"
              title={'Total Prize'}
              width={200}
              render={(value: any) => (
                <NumberField
                  color="black"
                  style={{
                    fontWeight: 700,
                    textAlign: 'center',
                  }}
                  value={value}
                />
              )}
            />
            <Table.Column
              dataIndex="sessionStartAt"
              title={'Session StartAt'}
              render={(value: any) => <DateFieldTimezone value={value} />}
            />
            <Table.Column
              dataIndex="sessionEndAt"
              title={'Session EndAt'}
              render={(value: any) => <DateFieldTimezone value={value} />}
            />
            <Table.Column
              dataIndex="isReward"
              title={'Reward'}
              render={(value: any, record: any) => {
                return value && user?.role === 'superadmin' ? (
                  <Button
                    type="primary"
                    onClick={() => onRewardClick(record.id)}
                  >
                    Click to reward points
                  </Button>
                ) : (
                  <></>
                );
              }}
            />
            <Table.Column
              dataIndex="winningNumbers"
              title={'Report'}
              render={(value: any, record: any) => {
                return value.length > 0 ? (
                  <Button
                    size="middle"
                    onClick={() => {
                      getReport(record.id);
                    }}
                    icon={<RedEnvelopeOutlined />}
                  />
                ) : (
                  <></>
                );
              }}
            />
          </Table>
        </div>
      </PageHeader>
      <Drawer
        open={isShowDrawerVisible}
        onClose={() => {
          setIsShowDrawerVisible(false);
        }}
        width="70%"
      >
        <Show
          title="Draw Result"
          canEdit={false}
          isLoading={false}
          recordItemId={showId}
          headerButtons={false}
          goBack={<div>back</div>}
        >
          <ShowReport id={showId} />
        </Show>
      </Drawer>
    </>
  );
};
