import React from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  UrlField,
} from '@refinedev/antd';
import { Table, Space, Image } from 'antd';

export const GameList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="slug" title="Slug" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex={['category', 'name']} title="Category" />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column
          dataIndex={['source']}
          title="Source"
          render={(value: any) => <UrlField value={value} />}
        />
        <Table.Column
          dataIndex="logo"
          title="Logo"
          render={(value: any) => <Image src={value} height={100} />}
        />

        <Table.Column dataIndex="sort" title="Sort" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
