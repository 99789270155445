import React from 'react';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import {
  Show,
  NumberField,
  TextField,
  UrlField,
  BooleanField,
  DateField,
} from '@refinedev/antd';
import { Image, Typography } from 'antd';

const { Title } = Typography;

export const GameShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Slug</Title>
      <TextField value={record?.slug} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Category</Title>
      <TextField value={record?.category.name} />
      <Title level={5}>Description</Title>
      <TextField value={record?.description} />
      <Title level={5}>Source</Title>
      <UrlField value={record?.source} />
      <Title level={5}>Logo</Title>
      <Image src={record?.logo} />
      <Title level={5}>Sort</Title>
      <NumberField value={record?.sort ?? ''} />
      <Title level={5}>Is active</Title>
      <BooleanField value={record?.isActive} />
      <Title level={5}>Created at</Title>
      <DateField value={record?.createdAt} />
      <Title level={5}>Updated at</Title>
      <DateField value={record?.updatedAt} />
    </Show>
  );
};
