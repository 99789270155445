import { AccessControl } from 'accesscontrol';

export enum AppRoles {
  SUPERADMIN = 'superadmin',
  MANAGER = 'manager',
  CUSTOMERCARE = 'customercare',
  MARKETING = 'marketing',
}

export const ac = new AccessControl();
ac.grant('anyone')
  .read([
    'user-management',
    'earn-management',
    'game-management',
    'tool',
    'lottery',
    'lottery-global',
    'football-fest',
  ]) // allow seeing parent menu
  // cs
  .grant(AppRoles.CUSTOMERCARE)
  .extend('anyone')
  .read([
    'users',
    'kyc-lv2',
    'hyperverge',
    'user-migrate',
    'document-assessment-kyc',
    'earn-claim-history',
    'earn-campaign',
    'earn-network',
    'campaign-reward',
    'earn-widget',
    'earn-category',
    'earn-user-history',
    'wallet-configs',
    'game',
    'game-category',
    'partner',
    'user-data-migration',
    'ota-reward-batch',
    'top-10-energy-stone',
    'push-notification',
    'lottery-result',
    'football-fest-match',
    'football-fest-category',
  ])
  .create([
    'kyc-lv2',
    'user-migrate',
    'user-data-migration',
    'ota-reward-batch',
  ])
  .update(['users', 'kyc-lv2', 'hyperverge', 'document-assessment-kyc'])

  // MARKETING
  .grant(AppRoles.MARKETING)
  .extend(AppRoles.CUSTOMERCARE)
  .read(['push-notification'])
  .create(['push-notification'])
  .update(['push-notification'])
  .delete(['push-notification'])

  // manager
  .grant(AppRoles.MANAGER)
  .extend(AppRoles.MARKETING)
  .update([
    'user-migrate',
    'earn-claim-history',
    'earn-network',
    'earn-campaign',
    'campaign-reward',
    'earn-widget',
    'earn-category',
    'game',
    'game-category',
    'partner',
    'ota-reward-batch',
    'push-notification',
  ])
  .create([
    'earn-network',
    'earn-campaign',
    'campaign-reward',
    'earn-widget',
    'earn-category',
    'game',
    'game-category',
    'partner',
    'push-notification',
  ])
  .delete([
    'user-migrate',
    'campaign-reward',
    'game',
    'game-category',
    'push-notification',
  ])
  // admin
  .grant(AppRoles.SUPERADMIN)
  .extend(AppRoles.MANAGER)
  .read([
    'cloudwatch',
    'lucky-wheel', // for menu
    'lucky-wheel-reward',
    'lucky-wheel-mission',
    'lottery-global-config',
    'lottery-ratio-config',
    'lottery-realtime-dashboard',
  ])
  .create(['users', 'kyc-lv2', 'hyperverge', 'lucky-wheel-reward'])
  .update([
    'users',
    'kyc-lv2',
    'hyperverge',
    'lucky-wheel-reward',
    'lucky-wheel-mission',
    'lottery-global-config',
    'lottery-ratio-config',
    'lottery-result',
    'football-fest-match',
    'football-fest-category',
  ])
  .delete(['partner', 'lucky-wheel-reward']);
