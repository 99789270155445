import { TagProps } from 'antd';

export enum LuckyWheelRewardType {
  no_reward = 'no_reward',
  energy_stone = 'energy_stone',
  otara = 'otara',
  ticket = 'ticket',
  mining_rate = 'mining_rate',
}

export const TypeTagColor: Record<LuckyWheelRewardType, TagProps['color']> = {
  no_reward: 'default',
  energy_stone: 'blue',
  otara: 'green',
  ticket: 'yellow',
  mining_rate: 'red',
};
