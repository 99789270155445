import React from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  CrudFilters,
} from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  BooleanField,
  NumberField,
} from '@refinedev/antd';
import {
  Table,
  Space,
  Select,
  Tag,
  Button,
  Form,
  FormProps,
  Input,
  Collapse,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { DateFieldTimezone } from '../../components/table/date-field';
const { Panel } = Collapse;

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch: (params: any) => {
      let filters: CrudFilters = [];
      const { q, id, referralCode } = params;

      filters = [
        {
          field: 'q',
          operator: 'eq',
          value: q,
        },
        {
          field: 'id',
          operator: 'eq',
          value: id,
        },
        {
          field: 'referralCode',
          operator: 'eq',
          value: referralCode,
        },
      ];

      return filters;
    },
  });
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <>
      <Collapse
        defaultActiveKey={['0']}
        onChange={onChange}
        style={{ marginBottom: 16 }}
      >
        <Panel header="Filter" key="1">
          <Filter formProps={searchFormProps} />
        </Panel>
      </Collapse>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="Id" sorter={{ multiple: 1 }} />
          <Table.Column
            dataIndex={['email']}
            title="Email"
            render={(value: any) => <Tag color="geekblue">{value}</Tag>}
          />
          <Table.Column dataIndex="name" title="Name" />
          <Table.Column
            dataIndex="kycLevel"
            title="Kyc Level"
            render={(value) => {
              let color;
              switch (value) {
                case 0:
                  color = 'green';
                  break;
                case 1:
                  color = 'red';
                  break;
                case 2:
                  color = 'blue';
                  break;
                default:
                  color = '';
                  break;
              }
              return <Tag color={color}>{value}</Tag>;
            }}
          />
          <Table.Column
            dataIndex={['phoneNumber']}
            title="Phone Number"
            // render={(value: any) => <PhoneFilled value={value} />}
          />
          <Table.Column
            dataIndex={['isRegisteredWithGoogle']}
            title="Is Registered With Google"
            render={(value: any) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex={['isRegisteredWithApple']}
            title="Is Registered With Apple"
            render={(value: any) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex={['otaBalance']}
            title="Ota Balance"
            render={(value: any) => (
              <Tag color="red">
                <NumberField value={value?.balance || 0} locale={'vi-VN'} />
              </Tag>
            )}
          />
          <Table.Column
            dataIndex={['userReferral']}
            title="RefCode"
            render={(value: any) => (
              <Tag color="purple">{value?.referralCode || ''}</Tag>
            )}
          />
          <Table.Column
            dataIndex={['createdAt']}
            title="Created At"
            sorter={{ multiple: 1 }}
            render={(value: any) => <DateFieldTimezone value={value} />}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="Email, Name, Phone Number etc."
          prefix={<SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Only User ID" name="id">
        <Input
          placeholder="User ID"
          prefix={<SearchOutlined />}
          type="number"
        />
      </Form.Item>
      <Form.Item label="RefCode" name="referralCode">
        <Input
          placeholder="RefCode"
          prefix={<SearchOutlined />}
          type="string"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
