import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { EARN_CLAIM_HISTORY_STATUS } from '../../constants';

export const EarnClaimHistoryEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const earnClaimHistoryData = queryResult?.data?.data;

  const { selectProps: userSelectProps } = useSelect({
    resource: 'users',
    defaultValue: earnClaimHistoryData?.userId,
    optionLabel: 'name',
  });

  const earnClaimHistoryStatus = {
    options: EARN_CLAIM_HISTORY_STATUS,
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="ID"
          name={['id']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="User"
          name={'userId'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userSelectProps} disabled />
        </Form.Item>
        <Form.Item
          label="Amount"
          name={['amount']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="BUSD Amount"
          name={['busdAmount']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Address"
          name={['address']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Admin Note"
          name={['adminNote']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Payment Note"
          name={['paymentNote']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name={['type']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Status"
          name={['status']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...earnClaimHistoryStatus} />
        </Form.Item>
        <Form.Item
          label="Created At"
          name={['createdAt']}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker disabled />
        </Form.Item>
        <Form.Item
          label="Updated At"
          name={['updatedAt']}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker disabled />
        </Form.Item>
      </Form>
    </Edit>
  );
};
