import React from 'react';
import { IResourceComponentsProps, useCan, useShow } from '@refinedev/core';
import {
  Show,
  NumberField,
  TagField,
  EmailField,
  TextField,
} from '@refinedev/antd';
import { Typography } from 'antd';
import { capitalize } from 'lodash';
import { RoleTagColor } from './constant/partnerRole';

const { Title } = Typography;

export const PartnerShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;
  const { data: canData } = useCan({
    action: 'delete',
    resource: 'partners',
  });

  return (
    <Show isLoading={isLoading} canDelete={!!canData?.can}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Email</Title>
      <EmailField value={record?.email} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Role</Title>
      <TagField
        value={capitalize(record?.role)}
        color={RoleTagColor[record?.role]}
      />
    </Show>
  );
};
