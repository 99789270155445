import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Select } from 'antd';
import { OTA_REWARD_STATUS } from '../../constants';

const otaRewardStatus = {
  options: OTA_REWARD_STATUS,
};

export const OtaRewardBatchEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Status"
          name={['status']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...otaRewardStatus} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
