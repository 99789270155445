import React from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  CrudFilters,
} from '@refinedev/core';
import {
  useTable,
  List,
  ShowButton,
  BooleanField,
  NumberField,
} from '@refinedev/antd';
import {
  Table,
  Space,
  Form,
  Input,
  Select,
  Button,
  FormProps,
  Collapse,
} from 'antd';
import { USER_DATA_SEARCH_OPTION } from '../../constants';
import { SearchOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export const UserDataMigrationList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch: (params: any) => {
      let filters: CrudFilters = [];
      const { search, searchType } = params;

      filters = [
        {
          field: 'search',
          operator: 'eq',
          value: search,
        },
        {
          field: 'searchType',
          operator: 'eq',
          value: searchType,
        },
      ];

      return filters;
    },
  });

  return (
    <>
      <Collapse defaultActiveKey={['0']} style={{ marginBottom: 16 }}>
        <Panel header="Filter" key="1">
          <Filter formProps={searchFormProps} />
        </Panel>
      </Collapse>

      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column dataIndex="userId" title="User ID" />

          <Table.Column dataIndex="migrationCode" title="Migration Code" />
          <Table.Column
            dataIndex={['isMigrated']}
            title="Is Migrated"
            render={(value: any) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex={['result']}
            title="Amount"
            render={(value: any) => <NumberField value={value.amount} />}
          />
          <Table.Column
            dataIndex={['result']}
            title="Before"
            render={(value: any) => <NumberField value={value.beforeBalance} />}
          />
          <Table.Column
            dataIndex={['result']}
            title="After"
            render={(value: any) => <NumberField value={value.afterBalance} />}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const dataSearchSelectProps = USER_DATA_SEARCH_OPTION;
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="search">
        <Input placeholder="Search user id" prefix={<SearchOutlined />} />
      </Form.Item>

      <Form.Item label="Search Type" name="searchType">
        <Select options={dataSearchSelectProps} />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
