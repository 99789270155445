import { FC, Key, useMemo, useState } from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  CrudFilters,
  useShow,
  useCustomMutation,
  useApiUrl,
  useResource,
  useGetIdentity,
} from '@refinedev/core';
import {
  useTable,
  List,
  ShowButton,
  Show,
  EditButton,
  DeleteButton,
} from '@refinedev/antd';
import {
  Table,
  Tag,
  Button,
  Form,
  FormProps,
  Input,
  Collapse,
  Space,
  Drawer,
} from 'antd';
import { DateFieldTimezone, HyperLinkField } from 'components/table';
import { USERS_LINK } from '../../constants';
import { CheckCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { UserMigrateDetail } from 'interfaces/user-migrate';
import { DrawerMigrateInfo } from './migrate-info-drawer';
import { UserMigrateStatus } from 'contexts/user-migrate';
import { UserData } from 'interfaces';

const { Panel } = Collapse;

export const UserMigrateList: FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { searchFormProps, tableProps } = useTable({
    syncWithLocation: true,
    onSearch: (params: any) => {
      let filters: CrudFilters = [];
      const { q, userId } = params;

      filters = [
        {
          field: 'q',
          operator: 'contains',
          value: q,
        },
        {
          field: 'userId',
          operator: 'eq',
          value: userId,
        },
      ];

      return filters;
    },
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const [isShowDrawerVisible, setIsShowDrawerVisible] = useState(false);
  const { queryResult, showId, setShowId } = useShow<UserMigrateDetail>();

  const { mutate, isLoading: updateManyIsLoading } = useCustomMutation();
  const apiUrl = useApiUrl();
  const { resource } = useResource();

  const { data: userData } = useGetIdentity<UserData>();

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const approveMultipleRequest = () => {
    mutate({
      url: `${apiUrl}/${resource?.name}/approve`,
      method: 'patch',
      values: {
        requestIds: selectedRowKeys,
      },
    });
  };

  const hasSelected = useMemo(
    () => selectedRowKeys.length > 0,
    [selectedRowKeys],
  );

  return (
    <>
      <Collapse defaultActiveKey={['0']} style={{ marginBottom: 16 }}>
        <Panel header="Filter" key="1">
          <Filter formProps={searchFormProps} />
        </Panel>
      </Collapse>
      <List
        headerProps={{
          subTitle: (
            <>
              <Button
                type="primary"
                onClick={approveMultipleRequest}
                disabled={!hasSelected}
                loading={updateManyIsLoading}
              >
                Batch Approve
              </Button>
              <span style={{ marginLeft: 8 }}>
                {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
              </span>
            </>
          ),
        }}
      >
        <Table {...tableProps} rowSelection={rowSelection} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            dataIndex="fromUserId"
            title="From User Id"
            render={(value: string) => (
              <HyperLinkField
                value={value}
                link={USERS_LINK}
                color="geekblue"
              />
            )}
          />
          <Table.Column
            dataIndex="fromUserEmail"
            title="From Email"
            render={(value: any) => <Tag color="geekblue">{value}</Tag>}
          />
          <Table.Column
            dataIndex="toUserId"
            title="To User Id"
            render={(value: string) => (
              <HyperLinkField value={value} link={USERS_LINK} color="green" />
            )}
          />
          <Table.Column
            dataIndex="toUserEmail"
            title="To User"
            render={(value: any) => <Tag color="green">{value}</Tag>}
          />
          <Table.Column
            dataIndex="group"
            title="Group"
            render={(value: any) => (
              <Tag color={value === 'manually-request' ? 'brown' : 'red'}>
                {value}
              </Tag>
            )}
          />
          <Table.Column
            dataIndex="status"
            title="Status"
            render={(value: any) => {
              if (value === 'pending') return <Tag color="brown">{value}</Tag>;
              else return <Tag color="green">{value}</Tag>;
            }}
          />
          <Table.Column
            dataIndex="createdAt"
            title="Created At"
            render={(value: any) => <DateFieldTimezone value={value} />}
          />
          <Table.Column
            dataIndex="updatedAt"
            title="Updated At"
            render={(value: any) => <DateFieldTimezone value={value} />}
          />
          <Table.Column
            title={translate('table.actions')}
            dataIndex="actions"
            render={(_, record: BaseRecord) => {
              const showApproveAndCancelButton = [
                UserMigrateStatus.PENDING,
                UserMigrateStatus.ERROR,
              ].includes(record.status);

              const onClickApprove = () => {
                mutate({
                  url: `${apiUrl}/${resource?.name}/approve`,
                  method: 'patch',
                  values: {
                    requestIds: [record.id],
                  },
                });
              };

              const isCurrentUserRequest = record.createdBy === userData?.id;

              return (
                <Space>
                  <ShowButton
                    size="small"
                    recordItemId={record.id}
                    onClick={() => {
                      setShowId(record.id);
                      setIsShowDrawerVisible(true);
                    }}
                  >
                    Info
                  </ShowButton>
                  {showApproveAndCancelButton && (
                    <EditButton
                      size="small"
                      recordItemId={record.id}
                      icon={<CheckCircleTwoTone twoToneColor="green" />}
                      onClick={onClickApprove}
                      loading={updateManyIsLoading}
                    >
                      Approve
                    </EditButton>
                  )}
                  {showApproveAndCancelButton && (
                    <DeleteButton recordItemId={record.id}>
                      {isCurrentUserRequest ? 'Cancel' : 'Reject'}
                    </DeleteButton>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </List>
      <Drawer
        open={isShowDrawerVisible}
        onClose={() => setIsShowDrawerVisible(false)}
        width="70%"
      >
        <Show isLoading={!queryResult.data?.data} recordItemId={showId}>
          {queryResult.data?.data && (
            <DrawerMigrateInfo userMigrateDetail={queryResult.data?.data} />
          )}
        </Show>
      </Drawer>
    </>
  );
};

const Filter: FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input placeholder="Email, Group, etc." prefix={<SearchOutlined />} />
      </Form.Item>
      <Form.Item label="Only User ID" name="userId">
        <Input
          placeholder="User ID"
          prefix={<SearchOutlined />}
          type="number"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
