import {
  IResourceComponentsProps,
  useApiUrl,
  useNotification,
} from '@refinedev/core';
import { Create, getValueFromEvent, useForm } from '@refinedev/antd';
import { Form, Row, Upload } from 'antd';
import { getFile } from '../../utils/upload-file';
import { HyperLinkField } from '../../components/table';
import { DOWNLOAD_CSV_SAMPLE_LINK } from '../../constants';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/lib';
import { flatMap } from 'lodash';

export const OtaRewardBatchCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, onFinish } = useForm();

  const apiUrl = useApiUrl();
  const token = localStorage.getItem('refine-access-token');

  const { open } = useNotification();

  const onUpload = (
    info: UploadChangeParam<
      UploadFile<{
        url: string;
        data: {
          userId: number;
          rewardType: string;
          amount: number;
          reason: string;
        }[];
      }>
    >,
  ) => {
    const { status } = info.file;

    if (status === 'done') {
      open?.({
        type: 'success',
        message: 'Upload Success',
      });

      return;
    }

    if (status === 'error') {
      open?.({
        type: 'error',
        message: 'Upload Failed',
      });

      return;
    }
  };

  const onHandleFinish = (values: any) => {
    const data = flatMap(
      values.url,
      ({
        response,
      }: UploadFile<{
        url: string;
        data: {
          userId: number;
          rewardType: string;
          amount: number;
          reason: string;
        }[];
      }>) => response?.data,
    );

    const url = values.url[0].response.url;

    onFinish({
      data,
      url,
    });
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Row align="stretch">
        <HyperLinkField
          value="CSV Sample Data File"
          link={DOWNLOAD_CSV_SAMPLE_LINK}
          linkOnly
        />
      </Row>
      <Form {...formProps} layout="vertical" onFinish={onHandleFinish}>
        <Form.Item label="CSV File">
          <Form.Item
            name="url"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/ota-reward-batch/upload-file`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              maxCount={1}
              onChange={onUpload}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};
