import { TagProps } from 'antd';

export enum PartnerRoles {
  PARTNER = 'partner',
  AMBASSADOR = 'ambassador',
}

export const RoleTagColor: Record<string, TagProps['color']> = {
  ambassador: 'red',
  partner: 'blue',
};
