import { IResourceComponentsProps, CrudFilters } from '@refinedev/core';
import { useTable, List, EmailField } from '@refinedev/antd';
import { Table, Tag, Button, Form, FormProps, Input, Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

export const WalletConfigList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch: (params: any) => {
      let filters: CrudFilters = [];
      const { address, id } = params;

      filters = [
        {
          field: 'address',
          operator: 'eq',
          value: address,
        },
        {
          field: 'id',
          operator: 'eq',
          value: id,
        },
      ];

      return filters;
    },
  });
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <>
      <Collapse
        defaultActiveKey={['0']}
        onChange={onChange}
        style={{ marginBottom: 16 }}
      >
        <Panel header="Filter" key="1">
          <Filter formProps={searchFormProps} />
        </Panel>
      </Collapse>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="Id" sorter={{ multiple: 1 }} />
          <Table.Column
            dataIndex={['userId']}
            title="User ID"
            render={(value: any) => <Tag color="geekblue">{value}</Tag>}
          />
          <Table.Column
            dataIndex={['email']}
            title="User Email"
            render={(value: any) => <EmailField value={value} />}
          />
          <Table.Column dataIndex="address" title="Address" />
          <Table.Column dataIndex={['chainId']} title="Chain ID" />
        </Table>
      </List>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="address">
        <Input placeholder="Address" prefix={<SearchOutlined />} />
      </Form.Item>
      <Form.Item label="Only User ID" name="id">
        <Input
          placeholder="User ID"
          prefix={<SearchOutlined />}
          type="number"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
