import React from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
} from '@refinedev/antd';
import { Table, Space, Tag } from 'antd';
import { NOTIFICATION_TYPE } from '../../constants';

export const PushNotificationList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          title={translate('Campaign Name')}
          width={200}
        />
        <Table.Column
          dataIndex="vnTitle"
          title={translate('VN-Title')}
          width={500}
        />
        <Table.Column
          dataIndex="vnMessage"
          title={translate('VN-Message')}
          width={700}
        />
        <Table.Column
          dataIndex="enTitle"
          title={translate('EN-Title')}
          width={500}
        />
        <Table.Column
          dataIndex="enMessage"
          title={translate('EN-Message')}
          width={700}
        />
        <Table.Column
          dataIndex={['startAt']}
          title={translate('StartAt')}
          render={(value: any) => {
            return <DateField format="DD/MM/YYYY" value={value.slice(0, -1)} />;
          }}
          width={180}
        />
        <Table.Column
          dataIndex={['endAt']}
          title={translate('EndAt')}
          render={(value: any) => {
            return <DateField format="DD/MM/YYYY" value={value.slice(0, -1)} />;
          }}
          width={180}
        />
        <Table.Column
          dataIndex={['hour', 'minute']}
          title={translate('Time')}
          render={(_, record: BaseRecord) => (
            <Tag color="blue">
              {record.hour}h:{record.minute}m
            </Tag>
          )}
        />
        <Table.Column
          dataIndex="type"
          title={translate('Type')}
          render={(type: string) =>
            type === 'push_now' ? (
              <Tag color="pink">{NOTIFICATION_TYPE[type]}</Tag>
            ) : (
              <Tag color="black">{NOTIFICATION_TYPE[type]}</Tag>
            )
          }
        />
        <Table.Column
          dataIndex={['isActive']}
          title={translate('Status')}
          render={(value: any, record: BaseRecord) => {
            const date = new Date(record.endAt?.slice(0, -1)).getTime();
            const now = new Date().getTime();
            return value ? (
              now - date > 0 && record.type !== 'push_now' ? (
                <Tag color="brown">expired</Tag>
              ) : (
                <Tag color="green">true</Tag>
              )
            ) : (
              <Tag color="red">false</Tag>
            );
          }}
        />
        <Table.Column
          dataIndex={['createdAt']}
          title={translate('CreatedAt')}
          render={(value: any) => (
            <DateField format="DD/MM/YYYY" value={value} />
          )}
          width={150}
        />
        <Table.Column
          title={translate('table.actions')}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
